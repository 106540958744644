<template>
    <card-component :title="getLabel()">
        <div class="row">
            <label>Informação da Empresa</label>
            <div class="col-md-5">
                <floating-label-component name="company_name" label="Nome da Empresa" v-model="value.company_name" />
            </div>
            <div class="col-md-3">
                <floating-label-component name="company_nipc" label="NIPC" v-model="value.company_nipc" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="company_ccc" label="Código da Certidão Comercial" v-model="value.company_ccc" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <floating-label-component name="company_address" label="Morada da Sede"
                    v-model="value.company_address" />
            </div>
            <div class="col-md-3">
                <floating-label-component name="company_zipcode" label="Código Postal"
                    v-model="value.company_zipcode" />
            </div>
            <div class="col-md-3">
                <floating-label-component name="company_zipcode" label="Localidade" v-model="value.company_zipcode" />
            </div>
        </div>
        <div class="row">
            <hr />
            <label>Representante Legal</label>
            <div class="col-md-3">
                <floating-label-component name="company_r_title" label="Titulo do Representante Legal"
                    v-model="value.company_r_title" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="company_r_name" label="Nome do Representante Legal"
                    v-model="value.company_r_name" />
            </div>
            <div class="col-md-3">
                <floating-label-component name="company_r_nif" label="NIF do Representante Legal"
                    v-model="value.company_r_nif" />
            </div>
            <div class="col-md-2">
                <drop-down-component name="company_language" placeholder="Língua" v-model="value.company_language" 
                    :items="clientLanguages" />
            </div>
        </div>
    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: Object,
        clientLanguages: Array,
    },
    methods: {
        getLabel() {
            if (this.value.business_type === 'BUY_AND_SELL') {
                return 'Identificação dos Compradores'
            }

            if (this.value.business_type === 'RENT') {
                return 'Identificação dos Arrendatários'
            }

            return 'Identificação dos Clientes'
        }
    }
}
</script>

