<template>
    <div>
        <div v-for="(invoice, i) in invoices" :key="i">
            <input type="text" :name="'invoices[' + i + '][id]'" :value="invoice.id" hidden />
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <label class="card-subtitle">Fatura <span v-html="i + 1"></span></label>
                        <button v-if="invoices.length > 1" class="btn btn-link" type="button" @click="deleteInvoice(i)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="btn-group w-20" role="group">
                        <input type="radio" class="btn-check" :name="'invoices[' + i + '][payment_completed]'"
                            :id="getRadioId(i, 1)" autocomplete="off" value="1"
                            :checked="invoice.payment_completed == 1">
                        <label class="btn btn-outline-secondary" :for="getRadioId(i, 1)">Sim</label>

                        <input type="radio" class="btn-check" :name="'invoices[' + i + '][payment_completed]'"
                            :id="getRadioId(i, 0)" autocomplete="off" value="0"
                            :checked="invoice.payment_completed == 0">
                        <label class="btn btn-outline-secondary" :for="getRadioId(i, 0)">Não</label>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-4">
                    <floating-label-currency-component label="Valor da Fatura" :name="'invoices[' + i + '][value]'"
                        v-model="invoice.value" />
                </div>
                <div class="col-md-4">
                    <floating-label-component label="Número" :name="'invoices[' + i + '][number]'"
                        v-model="invoice.number" />
                </div>
                <div class="col-md-4">
                    <floating-label-date-component label="Data de Emissão" :name="'invoices[' + i + '][invoice_date]'"
                        v-model="invoice.invoice_date" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center border-top py-2">
                        <label class="card-subtitle mb-0">Pagamentos</label>
                        <span @click="addPayment(i)">
                            <svg-vue icon="add_full" fill="#8BB8A2" />
                        </span>
                    </div>
                </div>
                <input type="text" :name="'invoices[' + i + '][id]'" :value="invoice.id" hidden />

                <div class="row" v-for="(payment, j) in invoice.payments" :key="j">
                    <div class="col-11">
                        <div class="row">
                            <input type="text" :name="'invoices[' + i + '][payments][' + j + '][id]'"
                                :value="payment.id" hidden />
                            <div class="col-md-6">
                                <floating-label-currency-component label="Valor Recebido"
                                    :name="'invoices[' + i + '][payments][' + j + '][value_received]'"
                                    v-model="payment.value_received" />
                            </div>

                            <div class="col-md-6">
                                <floating-label-date-component label="Data de Emissão"
                                    :name="'invoices[' + i + '][payments][' + j + '][payment_date]'"
                                    v-model="payment.payment_date" />
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <button v-if="invoice.payments.length > 1" class="btn btn-link" type="button"
                            @click="deletePayment(i, j)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group w-100" role="group">
            <button class="btn btn-secondary" type="button" @click="addInvoice">
                <svg-vue icon="plus" /> Fatura Adicional
            </button>
        </div>
    </div>

</template>

<script>
export default {
    mounted() {
        this.invoices = this.invoiceInvoices;

        if (this.invoices.length === 0) {
            this.addInvoice()
        }

    },
    props: {
        invoiceInvoices: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            invoices: [],
        }
    },

    methods: {
        addInvoice() {
            this.invoices.push({
                id: null,
                payment_completed: 0,
                value: null,
                number: null,
                issue_date: null,
                payments: [],
            });
        },
        addPayment(index) {
            this.invoices[index].payments.push({
                id: null,
                value_received: null,
                value_to_be_received: null,
                payment_date: null,
            });
        },
        deleteInvoice(i) {
            this.invoices.splice(i, 1);
        },
        deletePayment(i, j) {
            this.invoices[i].payments.splice(j, 1);
        },
        tooglePayed(i) {
            this.invoices[i].payment_completed = !this.invoices[i].payment_completed;
        },
        getRadioId(i, x) {
            return `radio_${i}_${x}`;
        }

    }
}
</script>
