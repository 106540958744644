<template>
    <form method="POST" ref="form" :action="route">
        <slot>{{ slot }}</slot>
        <div class="form-floating">
            <select id="selectorComponent" :name="name" class="form-select" v-model="selectedItem" @change="submitForm">
                <option :value="item.id" v-bind:key="item.id" v-for="item in itemsArray">{{ item.name }}</option>
            </select>
            <label for="selectorComponent">{{ placeholder }}</label>
        </div>
    </form>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        name: {
            type: String,
            required: true
        },
        route: {
            type: String,
            required: true
        },
        items: {
            type: String,
            required: true
        },
        selected: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: 'label'
        }
    },
    data() {
        return {
            itemsArray: JSON.parse(this.items),
            selectedItem: this.selected,
        }
    },
    methods: {
        submitForm: function () {
            this.$refs.form.submit();
        },
    }
}
</script>