<template>
    <section class="kpis kpis-v2">
        <div class="kpi-section">
            <div class="kpi-header" @click="sendTableEvent($event, 'NEW')">
                <div class="kpi-title">Novos</div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'NEW', 'BUY_AND_SELL')">
                    <p>CPCV</p>
                    <h5>{{ getValue('NEW', 'BUY_AND_SELL') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'NEW', 'RENT')">
                    <p>Arrendamento</p>
                    <h5>{{ getValue('NEW', 'RENT') }}</h5>
                </div>
            </div>
        </div>
        <div class="kpi-section inactive">
            <div class="kpi-header" @click="sendTableEvent($event, 'IN_PROGRESS')">
                <div class="kpi-title">Em Progresso</div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'IN_PROGRESS', 'BUY_AND_SELL')">
                    <p>CPCV</p>
                    <h5>{{ getValue('IN_PROGRESS', 'BUY_AND_SELL') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'IN_PROGRESS', 'RENT')">
                    <p>Arrendamento</p>
                    <h5>{{ getValue('IN_PROGRESS', 'RENT') }}</h5>
                </div>
            </div>
        </div>
        <div class="kpi-section">
            <div class="kpi-header" @click="sendTableEvent($event, 'IN_ANALYSIS')">
                <div class="kpi-title">Em Análise</div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'IN_ANALYSIS', 'BUY_AND_SELL')">
                    <p>CPCV</p>
                    <h5>{{ getValue('IN_ANALYSIS', 'BUY_AND_SELL') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'IN_ANALYSIS', 'RENT')">
                    <p>Arrendamento</p>
                    <h5>{{ getValue('IN_ANALYSIS', 'RENT') }}</h5>
                </div>
            </div>
        </div>
        <div class="kpi-section inactive">
            <div class="kpi-header" @click="sendTableEvent($event, 'COMPLETED')">
                <div class="kpi-title">Concluído</div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'COMPLETED', 'BUY_AND_SELL')">
                    <p>CPCV</p>
                    <h5>{{ getValue('COMPLETED', 'BUY_AND_SELL') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'COMPLETED', 'RENT')">
                    <p>Arrendamento</p>
                    <h5>{{ getValue('COMPLETED', 'RENT') }}</h5>
                </div>
            </div>
        </div>
        <div class="kpi-section">
            <div class="kpi-header">
                <div class="kpi-title">Total</div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, null, 'BUY_AND_SELL')">
                    <p>CPCV</p>
                    <h5>{{ getTotal('BUY_AND_SELL') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, null, 'RENT')">
                    <p>Arrendamento</p>
                    <h5>{{ getTotal('RENT') }}</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const selectedClass = 'kpi-header-selected';

export default {
    mounted() {

    },
    props: {
        stats: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        getValue(state, type) {
            try {
                return this.stats[state][type];
            }
            catch (e) {

            }
        },
        getTotal(type) {
            let total = 0;

            for (let s in this.stats) {
                total += Number.parseInt(this.stats[s][type]);
            }

            return total;
        },
        sendTableEvent(event, filter1, filter2) {

            let target = $(event.target);
            if (!target.hasClass('kpi') && !target.hasClass('kpi-header')) {
                target = target.parent();
            }

            if (target.hasClass(selectedClass)) {
                filter1 = null;
                filter2 = null;
            } else {
                $('.' + selectedClass).each((i, e) => {
                    $(e).removeClass(selectedClass);
                })
            }

            target.toggleClass(selectedClass);

            const filters = [];

            if (filter1) {
                filters.push({
                    'field': 'state',
                    'value': filter1,
                })
            }

            if (filter2) {
                filters.push({
                    'field': 'business_type',
                    'value': filter2,
                })
            }
            window.bus.$emit('filter-table', filters);
        }
    }
}
</script>

