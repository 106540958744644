<template>
    <div>
        <!-- Modal -->
        <div class="modal fade show" tabindex="-1" aria-modal="true" role="dialog" style="display:block">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <slot name="header">
                            Header
                        </slot>
                        <button type="button" @click="$emit('close')" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            modalOpen: false
        }
    },
    methods: {
        hideModal() {
            this.modalOpen = false;
        },
    }
}
</script>
