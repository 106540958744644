<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <label class="card-subtitle">Agência Externa</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <floating-label-component label="Agência Angariadora" name="external_agency" v-model="invoiceCopy.external_agency" />
            </div>
            <div class="col-md-6">
                <floating-label-component name="external_agent" label="Agente Angariador" v-model="invoiceCopy.external_agent" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <floating-label-component label="NIPC" name="external_nipc" v-model="invoice.external_nipc" />
            </div>
            <div class="col-md-6">
                <floating-label-component label="Email" name="external_email" v-model="invoice.external_email" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <floating-label-component label="Morada do Cliente" name="external_address"
                    v-model="invoice.external_address" />
            </div>
            <div class="col-md-3">
                <floating-label-component label="Código Postal" name="external_zipcode"
                    v-model="invoice.external_zipcode" />
            </div>
            <div class="col-md-3">
                <floating-label-component label="Localidade" name="external_city" v-model="invoice.external_city" />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            invoiceCopy: { ...this.invoice },
        }
    }

}
</script>
