

<template>
    <div class="form-floating mb-3">
        <input type="hidden" :name="name" :value="cleanValue" />
        <input v-bind="$attrs" type="text" class="form-control" :placeholder="label" v-model="formattedValue"
            @change="onChange($event)" v-mask="{ 'alias': 'datetime', inputFormat: inputFormat }" />
        <label>{{ label }}</label>
    </div>
</template>

<script>
export default {
    mounted() {
        this.cleanValue = this.value;
        if (this.cleanValue) {
            this.formattedValue = moment(this.value).format(this.inputFormat.toUpperCase());
        }
    },
    props: {
        name: {
            type: String,
        },
        value: {
            type: String | Number,
        },
        label: {
            type: String,
        },
    },
    data() {
        return {
            cleanValue: null,
            formattedValue: null,
            inputFormat: window.config.format.date,
        };
    },
    methods: {
        onChange(event) {
            const value = event.target.value;
            this.cleanValue = moment(value, this.inputFormat.toUpperCase()).format('YYYY-MM-DD');
            this.$emit('input', this.cleanValue);
        },
    }

}
</script>

