<template>
    <div class="history">
        <div v-for="(row, i) in dataChanges" class="history-block" @click="toggleExpand(i)">
            <div class="row">
                <div class="col">
                    <span class="user">{{ row.user.name }}</span>
                </div>
                <div class="col-3"><span class="changes">{{ row.changes.total }} alterações</span></div>
                <div class="col-2 text-right">
                    <div v-if="isExpanded(row)">
                        <svg-vue icon="arrow_down1" :transform="'rotate(180)'" />
                    </div>
                    <div v-else>
                        <svg-vue icon="arrow_down1" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="datetime">{{ formatDate(row.date) }}</div>
                </div>
            </div>
            <div v-if="isExpanded(row)" class="changes-block">

                <div class="change" v-if="row.changes.added && row.changes.added.length">
                    <span class="label-added">Adicionado <span v-if="row.changes.label">{{ row.changes.label }}</span></span>
                    <div v-for="l in row.changes.added">
                        <span class="label-key">{{ l.key }}</span> <span class="label-value">({{ l.value }})</span>
                    </div>
                </div>
                <div class="change" v-if="row.changes.updated && row.changes.updated.length">
                    <span class="label-updated">Alterado <span v-if="row.changes.label">{{ row.changes.label }}</span></span>
                    <div v-for="l in row.changes.updated">
                        <span class="label-key">{{ l.key }}</span> <span class="label-value">({{ l.value }})</span>
                    </div>
                </div>
                <div class="change" v-if="row.changes.removed && row.changes.removed.length">
                    <span class="label-removed">Removido <span v-if="row.changes.label">{{ row.changes.label }}</span></span>
                    <div v-for="l in row.changes.removed">
                        <span class="label-key">{{ l.key }}</span> <span class="label-value">( {{ l.value }} )</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        this.dataChanges = this.changes;
    },
    props: {
        changes: {
            type: Array,
        },
    },
    data() {
        return {
            dataChanges: []
        }
    },
    methods: {
        // getThumbnail(log) {
        //     return log.user.image || null
        // },
        isExpanded(log) {
            return !!log.expanded
        },
        toggleExpand(i) {
            const log = this.dataChanges.find((log, count) => {
                if (count === i) {
                    return log;
                }
                return false;
            });
            log.expanded = !log.expanded;
            this.dataChanges.splice(i, 1, log)
        },
        formatDate(date) {
            return window.moment(date).format('llll');
        }
    }
}
</script>
