<template>
    <div>
        <div class="row">
            <div class="col">
                <input type="hidden" name="id" :value="property.id" />
                <card-component title="Angariação">
                    <div class="row">
                        <div class="col-md-3">
                            <drop-down-component name="agency_id" label="Agência" v-model="property.agency_id"
                                :items="this.agencies" @onChange="selectAgency($event)" />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="agent_id" label="Agente" v-model="property.agent_id"
                                :items="this.getUsersForField('agent_id')" allow-empty />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="manager1_id" label="Responsável Check List"
                                v-model="property.manager1_id" :items="this.getUsersForField('manager1_id')"
                                allow-empty />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="manager2_id" label="Responsável Análise"
                                v-model="property.manager2_id" :items="this.getUsersForField('manager2_id')"
                                allow-empty />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <floating-label-component name="cmi" label="ID" v-model="property.cmi"
                                :mask="{ 'regex': '[0-9]{9}-[0-9]{1,10}' }" />
                        </div>
                        <div class="col-md-3">
                            <floating-label-component name="client_name" label="Cliente"
                                v-model="property.client_name" />
                        </div>
                        <div class="col-md-6">
                            <floating-label-component name="city" label="Localidade" v-model="property.city" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <option-component name="business_type" label="Tipo de Negócio"
                                    v-model="property.business_type" :options="propertyBusinessTypes" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <option-component name="contract_type" label="Tipo de Cliente"
                                    v-model="property.contract_type" :options="propertyContractTypes" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <floating-label-text-area-component name="observations" label="Observações"
                                placeholder="Observações" rows="3" v-model="property.observations" />
                        </div>
                    </div>
                </card-component>
            </div>
        </div>

        <div class="row" v-if="property.id">
            <div class="col">
                <card-component title="Documentos">
                    <div class="wow-documents-grid">
                        <div v-for="documentType in filterDocumentTypes(propertyDocumentTypes)">
                            <property-documents-component :label="documentType.name" name="documents"
                                :doc-type="documentType.id" :property-id="property.id" :inner-label="documentType.name"
                                :description="getDocumentTypeDescription(documentType.id)"
                                :documents="getDocumentsByType(documentType.id)" />
                        </div>
                    </div>
                </card-component>
            </div>
        </div>

        <div class="row" v-if="property.id">
            <div class="col">
                <card-component title="Relatório de Angariação">
                    <div class="row">
                        <div class="col-12">
                            <drop-down-component name="documents_status" label="Estado"
                                v-model="property.documents_status" :items="propertyDocumentStatusTypes" allow-empty />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <floating-label-text-area-component name="analysis_report" label="Relatório de Angariação"
                                placeholder="Relatório de Angariação" rows="5" v-model="property.analysis_report" />
                        </div>
                    </div>
                </card-component>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    mounted() {
        this.selectedAgency = this.property.agency_id ? this.agencies.find(a => a.id === this.property.agency_id) : this.agencies[0];
    },
    props: {
        originalProperty: Object,
        propertyDocumentTypes: Array,
        propertyDocumentTypeDescriptions: Array,
        propertyBusinessTypes: Array,
        propertyContractTypes: Array,
        propertyDocumentStatusTypes: Array,
    },
    data() {
        return {
            agencies: window.agencies,
            property: { ...this.originalProperty },
            selectedAgency: null,
        }
    },
    methods: {
        selectAgency(id) {
            this.selectedAgency = this.agencies.find(a => a.id === id);
        },
        getDocumentsByType(type) {
            const document = this.property.documents.find(d => d.type === type);

            if (!document) {
                return {
                    option: 0,
                    files: []
                };
            }

            return {
                option: document.option,
                files: document.files.map(f => f.file_id)
            }

        },
        getDocumentTypeDescription(typeId) {
            return this.propertyDocumentTypeDescriptions.find(t => t.id === `${typeId}_DESCRIPTION`)?.name;
        },
        filterDocumentTypes(documentTypes) {
            let filterBy = [];
            switch (this.property.contract_type) {
                case 'SINGULAR':
                    filterBy = [
                        'CMI',
                        'CARTAO_CIDADAO',
                        'VERIFICACAO_ASSINATURA',
                        'ESTADO_CIVIL',
                        'HABILITACAO_HERDEIROS',
                        'CERTIDAO_REGISTO_PREDIAL',
                        'CADERNETA_PREDIAL_URBANA',
                        'CERTIFICADO_ENERGETICO',
                        'LICENCA_UTILIZACAO',
                        'FICHA_TECNICA_HABITACAO',
                        'CERTIDAO_INFRASTUTURAS',
                        'PLANTA',
                        'PLANTA_LOCALIZACAO',
                        'AREAS',
                        'MORADA',
                        'AFETACAO',
                        'PROCURACAO',
                        'IDENTIFICACAO_PROCURADORES',
                        'ONUS',
                    ];
                    break;

                case 'COLLECTIVE':
                    filterBy = [
                        'CMI',
                        'CARTAO_CIDADAO_GERENTES',
                        'VERIFICACAO_ASSINATURA',
                        'CERTIDAO_COMERCIAL',
                        'CODIGO_RCBE',
                        'ATA_ALIENACAO',
                        'CERTIDAO_REGISTO_PREDIAL',
                        'CADERNETA_PREDIAL_URBANA',
                        'CERTIFICADO_ENERGETICO',
                        'LICENCA_UTILIZACAO',
                        'FICHA_TECNICA_HABITACAO',
                        'CERTIDAO_INFRASTUTURAS',
                        'PLANTA',
                        'PLANTA_LOCALIZACAO',
                        'AREAS',
                        'MORADA',
                        'AFETACAO',
                        'PROCURACAO',
                        'IDENTIFICACAO_PROCURADORES',
                        'ONUS',
                    ];
                    break;
            }

            return documentTypes.filter(docType => {
                return filterBy.includes(docType.id);
            });
        },

        getUsersForField: function (field) {
            if (!this.selectedAgency) {
                return [];
            }

            const roleIds = window.modelConfiguration?.Property && window.modelConfiguration.Property[field] || []
            const roles = Object.values(this.selectedAgency.roles).filter(r => roleIds.find(id => id === r.id));
            const users = roles.map(r => r.users).flat()
            let result = [];
            const map = new Map();
            for (const item of users) {
                if (!map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name
                    });
                }
            }
            result.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

            return result;
        },
    }
}
</script>
