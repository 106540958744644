<template>
    <div id="juridical-support-component">
        <div class="row">
            <div class="col">
                <input type="hidden" name="id" :value="juridicalSupport.id" />
                <card-component title="Detalhes do Pedido">
                    <div class="row">
                        <div class="col-md-4">
                            <drop-down-component name="agency_id" label="Agência" v-model="juridicalSupport.agency_id"
                                :items="this.agencies" @onChange="selectAgency($event)" />
                        </div>
                        <div class="col-md-4">
                            <drop-down-component name="agent_id" label="Agente" v-model="juridicalSupport.agent_id"
                                :items="this.getUsersForField('agent_id')" allow-empty />
                        </div>
                        <div class="col-md-4">
                            <floating-label-component name="phone" label="Telemóvel"
                                v-model="juridicalSupport.phone" />
                        </div>
                        <div class="col-md-6">
                            <drop-down-component name="request_type" label="Tipo de Pedido"
                                v-model="juridicalSupport.request_type" :items="juridicalSupportRequestTypes" />
                        </div>
                        <div class="col-md-6" v-if="juridicalSupport.id">
                            <drop-down-component name="manager1_id" label="Responsável do Pedido"
                                v-model="juridicalSupport.manager1_id" :items="this.getUsersForField('manager1_id')"
                                allow-empty />
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <files-upload innerLabel="Carregar Documentos" name="documents" v-model="juridicalSupport.file_id"
                                :file-ids="fileIds"
                                @fileRemoved="removeFile"
                                @onChange="fileChanged($event)" />
                        </div> -->
                        <div class="col12">
                            <floating-label-text-area-component name="description" label="Descrição do Pedido"
                                placeholder="Descreva o Pedido" rows="8" v-model="juridicalSupport.description" />
                        </div>
                    </div>
                </card-component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.selectedAgency = this.juridicalSupport.agency_id ? this.agencies.find(a => a.id === this.juridicalSupport.agency_id) : this.agencies[0];

        nextTick(() => {
            if (this.hasToAssignManager) {
                const content = $('#juridical-support-component')[0];
                const tags = ["input", "button", "textarea", "select"];
                tags.forEach(tagName => {
                    const nodes = content.getElementsByTagName(tagName);
                    for (let i = 0; i < nodes.length; i++) {
                        if (nodes[i].name === 'manager1_id' && this.canAssignManager) {
                            continue;
                        }
                        nodes[i].disabled = true;
                        nodes[i].tabIndex = -1;
                    }
                });

            }
        })

    },
    props: {
        originalJuridicalSupport: Object,
        juridicalSupportRequestTypes: Array,
        hasToAssignManager: {
            type: Boolean,
            default: false,
        },
        canAssignManager: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            agencies: window.agencies,
            juridicalSupport: { ...this.originalJuridicalSupport },
            selectedAgency: null,
        }
    },
    computed: {
        fileIds() {
            if (!this.juridicalSupport.files || this.juridicalSupport.files.length === 0) {
                return [];
            }
            return this.juridicalSupport.files.map(file => file.id);
        }
    },
    methods: {
        selectAgency(id) {
            this.selectedAgency = this.agencies.find(a => a.id === id);
        },
        getUsersForField: function (field) {
            if (!this.selectedAgency) {
                return [];
            }

            const roleIds = window.modelConfiguration?.JuridicalSupport && window.modelConfiguration.JuridicalSupport[field] || []
            const roles = Object.values(this.selectedAgency.roles).filter(r => roleIds.find(id => id === r.id));
            const users = roles.map(r => r.users).flat()
            let result = [];
            const map = new Map();
            for (const item of users) {
                if (!map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name
                    });
                }
            }
            result.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

            return result;
        },
        fileChanged(event) {
            console.log('fileChanged');
            console.log(event);
            for (let i = 0; i < event.target.files.length; i++) { // Loop through all selected files
                console.log('each');
                console.log(event.target.files[i]);
                this.fileData.push(event.target.files[i]); // Add each file to the fileData array
            }
        },
        removeFile(fileId) {
            console.log('removeFile');
            console.log(fileId);

            const data = {
                juridical_support_id: this.juridicalSupport.id,
                file_id: fileId
            }
            axios.post(window.moduleJuridicalSupportFileUrlDelete, data)
                .then(response => {
                    toastr.success("Documento removido com sucesso.");
                }).catch(e => {
                    toastr.error("Erro ao remover Documento.");
                }).finally(e => {
                })

        },
        createInstance() {
            console.log('createInstance');

            const formData = new FormData();
            this.fileData.forEach((file, index) => {
                formData.append(`file${index + 1}`, file); // Append each file to the formData
            });
            // Include other form data
            // Send formData to server
        },
    }
}
</script>
