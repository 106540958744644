<template>
    <div class="row">
        <div class="col">
            <card-component title="Informações do Template">
                <div class="row">
                    <div class="col-6">
                        <floating-label-component name="name" label="Nome do Template" v-model="template.name" />
                    </div>
                    <div class="col-6">
                        <drop-down-component name="marketing_category_id" placeholder="Categoria"
                            v-model="template.marketing_category_id" :items="this.categories" />
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6 col-md-6">
                        <floating-label-component name="version" label="Versão do Template"
                            v-model="template.version" />
                    </div>
                    <div class="col-6 col-md-6">
                        <drop-down-component name="marketing_model_id" placeholder="Modelo" v-model="template.marketing_model_id"
                            :items="this.templateTypes" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6">
                        <drop-down-component name="file_types" placeholder="Tipo de Formato(s)"
                            v-model="template.file_types" :items="this.formatTypes" />
                    </div>

                    <div class="col-6 col-md-6">
                        <drop-down-component name="dimension_type" placeholder="Tipo" v-model="template.dimension_type"
                            :items="this.dimensionTypes" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6">
                        <floating-label-component name="dimension_width" label="Largura"
                            v-model="template.dimension_width" />
                    </div>
                    <div class="col-6 col-md-6">
                        <floating-label-component name="dimension_height" label="Altura"
                            v-model="template.dimension_height" />
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-12 col-md-6">
                        <option-component name="has_back_file" label="Inclui Verso?" v-model="template.has_back_file"
                            :options="this.booleanTypes" @onChange="changed($event)" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 col-md-6">
                        <file-upload-component label="Frente" name="file_id" icon="pay_slip" :value="template.file_id" />
                    </div>
                    <div class="col-6 col-md-6">
                        <file-upload-component v-if="this.showBackFile" label="Verso" name="file_back_id" icon="pay_slip"
                            :value="template.file_back_id" />
                    </div>
                </div>
            </card-component>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        template: Object,
        // templateTypes: Array,
        // dimensionTypes: Array,
        // formatTypes: Array,
        categories: Array,
        booleanTypes: Array,
    },
    data() {
        return {
            showBackFile: this.template.has_back_file === 'YES',
        }
    },
    methods: {
        changed(option) {
            this.showBackFile = option === 'YES';
        }
    }
}
</script>
