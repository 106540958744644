<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <label class="card-subtitle">Agência Remax</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <floating-label-component label="Agência Angariadora" name="external_agency" v-model="invoiceCopy.external_agency" />
            </div>
            <div class="col-md-6">
                <floating-label-component name="external_agent" label="Agente Angariador" v-model="invoiceCopy.external_agent" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            invoiceCopy: { ...this.invoice },
        }
    }

}
</script>
