<template>
    <div class="row">
        <div class="col-5">
            <select v-model="from" name="from[]" class="form-control" size="8" multiple="multiple">
                <option v-for="item in itemsCopy" v-bind:value="item.id">
                    {{ item[labelFrom] }}
                </option>
            </select>
        </div>

        <div class="col-2 d-grid gap-2">
            <button type="button" class="btn btn-secondary" @click="addAll()"> <svg-vue icon="caret-forward"
                    fill="#FFF" /></button>
            <button type="button" class="btn btn-secondary" @click="addSelected()" :disabled="hideAdd()"> <svg-vue
                    icon="caret-right" fill="#FFF" /></button>
            <button type="button" class="btn btn-secondary" @click="removeSelected()" :disabled="hideRemove()"> <svg-vue
                    icon="caret-left" fill="#FFF" /></button>
            <button type="button" class="btn btn-secondary" @click="removeAll()"> <svg-vue icon="caret-backward"
                    fill="#FFF" /></button>
        </div>

        <div class="col-5">
            <select v-model="to" class="form-control" size="8" multiple="multiple">
                <option v-for="item in selectedCopy" v-bind:value="item.id">
                    {{ item[labelFrom] }}
                </option>
            </select>

            <input :name="name" v-for="item in selectedCopy" type="hidden" v-model="item.id" />
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.selectedCopy = [...this.selected.map(id => this.getItem(id))];
        this.itemsCopy = [...this.items.filter(i => !this.selectedCopy.find(c => c.id === i.id))];

        this.sort();
    },
    props: {
        name: String,
        items: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Array,
            default: () => [],
        },
        labelFrom: {
            type: String,
            default: 'name',
        }
    },
    data() {
        return {
            from: [],
            to: [],
            itemsCopy: [],
            selectedCopy: [],
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        },
        getItem(id) {
            return this.items.find(i => i.id === id);
        },
        hideAdd() {
            return !this.from.length;
        },
        hideRemove() {
            return !this.to.length;
        },
        addAll() {
            this.itemsCopy = [];
            this.selectedCopy = [...this.items];
            this.from = [];
            this.to = [];
            this.sort();
        },
        removeAll() {
            this.itemsCopy = [...this.items];
            this.selectedCopy = [];
            this.from = [];
            this.to = [];
            this.sort();
        },
        addSelected() {
            this.selectedCopy = [...this.selectedCopy, ...this.from.map(this.getItem)];
            this.itemsCopy = this.itemsCopy.filter(i => !this.from.find(id => id === i.id))
            this.from = [];
            this.sort();
        },
        removeSelected() {
            this.selectedCopy = this.selectedCopy.filter(i => !this.to.find(id => id === i.id))
            this.itemsCopy = [...this.itemsCopy, ...this.to.map(this.getItem)]
            this.to = [];
            this.sort();
        },
        sort() {

            this.itemsCopy.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

            this.selectedCopy.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
        },

    }
}
</script>

