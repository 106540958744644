<template>
    <div>
        <div class="row form-row">
            <div class="col">
                <drop-down-component label="Agências" v-model="selectedAgencyId" :items="this.getAvailableAgencies()" />
            </div>
            <div class="col">
                <button type="button" class="btn btn-success" @click="addAgency"
                    v-show="selectedAgencyId">+Agência</button>
            </div>
        </div>
        <div class="row form-row" v-for="row in rows">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col">
                        <label>{{ row.agency.name }}</label>
                        <input type="text" class="d-none" :name="'agencies[' + row.agency.id + '][agency_id]'"
                            :value="row.agency.id" />
                    </div>
                    <div class="col">
                        <button class="btn btn-danger" type="button" @click="deleteAgency(row)">X</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <select class="form-control" v-model="row.selectedRelatedUserId">
                            <option :value="0">Utilizador</option>
                            <option :value="relatedUser.id" v-for="relatedUser in getAvailableRelatedUsers(row)">{{
                                relatedUser.name
                            }}
                            </option>
                        </select>
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-success" @click="addRelatedUser(row)"
                            v-show="row.selectedRelatedUserId">+ Utilizador</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <ul>
                            <li v-for="relatedUser in row.relatedUsers">
                                {{ relatedUser.name }}<span @click="removeRelatedUser(row, relatedUser)">×</span>
                                <input type="text" class="d-none" :name="'agencies[' + row.agency.id + '][users][]'"
                                    :value="relatedUser.id" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        for (const data of this.userAgencies) {
            const agency = this.agencies.find(a => a.id == data.agency_id);
            if (agency) {
                this.rows.push({
                    agency: agency,
                    relatedUsers: agency?.users.filter(u => (data['users'] || []).find(x => x === u.id)) || [],
                    selectedRelatedUserId: null,
                });
            }
        }
    },
    props: {
        userAgencies: Array,
        agencies: Array,
        user: Object,
    },
    data() {
        return {
            rows: [],
            selectedAgencyId: null,
        }
    },
    methods: {
        addAgency: function () {
            const agency = this.agencies.find(a => a.id === this.selectedAgencyId);
            this.rows.push({
                agency: agency,
                relatedUsers: [],
                selectedRelatedUserId: null,
            });

            this.selectedAgencyId = null;
        },
        deleteAgency: function (row) {
            this.rows = this.rows.filter(r => r.agency.id !== row.agency.id);
        },
        getAvailableAgencies() {
            return [
                {
                    id: null,
                    name: ''
                },
                ...this.agencies.filter(a => !this.rows.find(r => r.agency.id === a.id))];
        },
        getAvailableRelatedUsers(row) {
            return this.agencies.find(a => a.id === row.agency.id)?.users?.filter(u => !row.relatedUsers.find(r => r?.id === u.id)).filter(u => u.id !== this.user?.id);
        },
        addRelatedUser: function (row) {
            const user = this.agencies.find(a => a.id === row.agency.id).users.find((u) => u.id === row.selectedRelatedUserId);
            row.relatedUsers.push(user);
            row.selectedRelatedUserId = null;
        },
        removeRelatedUser: function (row, subrow) {
            row.relatedUsers = row.relatedUsers.filter(r => r.id !== subrow.id);
        },

    }
}
</script>


<style>
.form-row {
    border: 1px solid #e2e2e2;
    margin: 10px;
    padding: 20px;
    background: #f2f2f2;
}
</style>