<template>
    <div>
        <p>{{ title }}</p>
        <p>{{ totalRange2 }} ultimos 180 dias</p>

        <p>{{ totalRange2 - totalRange1 }}</p>

        <p>{{ totalRange1 }} Periodo Anterior</p>

        <p>{{ rangesRatio }}</p>
        <Bar id="my-chart-id" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
            :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
    </div>
</template>
  
<script>
import { Bar } from 'vue-chartjs/legacy'

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    mounted() {
        if (!this.data) {
            return;
        }

        const { title, data } = this.data;
        this.title = title;
        this.chartData.labels = data[1].map(k => k.date);
        this.chartData.datasets[0].data = data[0].map(k => k.amount);
        this.chartData.datasets[1].data = data[1].map(k => k.amount);
        // console.log(this.chartData.labels);
        // console.log(this.chartData.datasets[0].data);
        // console.log(this.chartData.datasets[1].data);
    },
    props: {
        data: {
            type: Object,
        },
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 300
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            title: '',
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July'
                ],
                datasets: [
                    {
                        barPercentage: 0.5,
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        data: [40, 39, 10, 40, 39, 80, 40],
                        stacked: true
                    },
                    {
                        barPercentage: 0.5,
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        data: [20, 139, 100, 140, 139, 180, 140],
                        stacked: true
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.4  // smooth lines
                    },
                },
                scales: {
                    y: {
                        display: false,
                        beginAtZero: true,
                    }
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    subtitle: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    }
                },
                layout: {
                    padding: 5
                }

            }
        }
    },
    computed: {
        // a computed getter
        rangesRatio() {
            return Math.round(this.totalRange1 / this.totalRange2) + '%';
        },
        totalRange1() {
            const totalRange1 = this.chartData.datasets[0].data.reduce((p, c) => p + c, 0);
            return totalRange1;
        },
        totalRange2() {
            const totalRange2 = this.chartData.datasets[1].data.reduce((p, c) => p + c, 0);
            return totalRange2;
        },
    }
}
</script>
  