<template>
    <div>
        <card-component title="Propostas">

            <div class="row" v-for="(proposal, i) in proposals" :key="i">
                <input v-if="proposal.id" type="text" :name="'proposals[' + i + '][id]'" :value="proposal.id" hidden />
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <label class="card-subtitle">Proposta <span v-html="(i + 1)"></span></label>
                        <div class="form-check" v-if="proposal.id">
                            <input class="form-check-input" type="radio" name="credit_proposal_id"
                                :value="credit.credit_proposal_id" :checked="proposal.id === credit.credit_proposal_id"
                                @click="selectProposal(proposal)">
                            <label class="form-check-label">
                                Proposta selecionada
                            </label>
                        </div>
                        <button class="btn btn-link" type="button" @click="deleteProposal(i)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>

                <div class="col">
                    <div class="form-floating mb-3">
                        <drop-down-component :name="'proposals[' + i + '][bank_id]'" label="Banco"
                            v-model="proposal.bank_id" :items="bankOptions"></drop-down-component>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :name="'proposals[' + i + '][counter]'"
                            v-model="proposal.counter" label="Balcão">
                        <label>Balcão</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :name="'proposals[' + i + '][manager]'"
                            v-model="proposal.manager" label="Gestor">
                        <label>Gestor</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :name="'proposals[' + i + '][phone]'"
                            v-model="proposal.phone" label="Telefone">
                        <label>Telefone</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating mb-3">
                        <floating-label-date-component :name="'proposals[' + i + '][date]'" v-model="proposal.date"
                            label="Data" />
                    </div>
                </div>
            </div>
            <button class="btn btn-secondary w-100" type="button" @click="addProposal">
                <svg-vue icon="plus" /> Proposta
            </button>

        </card-component>

        <card-component title="Dados de Financiamento">
            <div class="row">
                <div class="col">
                    <floating-label-component disabled label="Banco Selecionado" :value="getSelectedBank()?.name" />
                </div>
                <div class="col">
                    <floating-label-component type="number" max="720" name="funding_deadline" label="Prazo (meses)"
                        v-model="credit.funding_deadline" />
                </div>
                <div class="col">
                    <floating-label-percentage-component name="funding_spread" label="Spread"
                        v-model="credit.funding_spread" />
                </div>
                <div class="col">
                    <floating-label-currency-component label="Mensalidade" name="funding_monthly_payment"
                        v-model="credit.funding_monthly_payment" />
                </div>
                <div class="col">
                    <floating-label-currency-component label="Valor do Financiamento" name="funding_value"
                        v-model="credit.funding_value" />
                </div>
            </div>
        </card-component>

        <card-component title="Dados do Imóvel">
            <div class="row">
                <div class="col-md-8">
                    <floating-label-component label="Morada" name="property_address"
                        v-model="credit.property_address" />
                </div>
                <div class="col-md-4">
                    <floating-label-component label="Localidade" name="property_locality"
                        v-model="credit.property_locality" />
                </div>
                <div class="col-md-4">
                    <floating-label-component label="Angariador" name="property_salesman"
                        v-model="credit.property_salesman" />
                </div>
                <div class="col-md-4">
                    <floating-label-component label="Angariador Telefone" name="property_salesman_phone"
                        v-model="credit.property_salesman_phone" />
                </div>
                <div class="col-md-4">
                    <floating-label-currency-component label="Valor de Aquisição" name="property_value_aquisition"
                        v-model="credit.property_value_aquisition" />
                </div>
            </div>
        </card-component>

    </div>
</template>

<script>
export default {
    mounted() {
        if (this.credit.credit_proposal_id !== null) {
            this.proposals.forEach(p => {
                if (p.id === this.credit.credit_proposal_id) {
                    this.selectProposal(p);
                }
            })
        }

        if (this.proposals.length === 0) {
            this.addProposal()
        }
    },
    props: {
        oCredit: {
            type: Object,
            required: true,
        },
        oProposals: {
            type: Array,
            required: true,
        },
        bankOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            credit: { ...this.oCredit },
            proposals: [...this.oProposals],
            selectedProposal: null,
            config: { ...window.config },
        }
    },
    methods: {
        addProposal() {
            this.proposals.push({
                bank_id: null,
                counter: null,
                manager: null,
                phone: null,
                date: null,
            });
        },
        deleteProposal(itemIndex) {
            const proposal = this.proposals[itemIndex];
            if (proposal.id === this.credit.credit_proposal_id) {
                this.credit.credit_proposal_id = null;
            }

            this.proposals.splice(itemIndex, 1);
        },
        getSelectedBank() {
            return this.bankOptions.find(b => b.id === this.selectedProposal?.bank_id);
        },
        selectProposal(proposal) {
            this.selectedProposal = proposal;
            this.credit.credit_proposal_id = proposal.id;
        }
    }
}
</script>
