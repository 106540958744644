<template>
    <card-component title="Condições do Negócio">
        <div class="row">
            <div class="col-md-3">
                <floating-label-currency-component label="Valor da Venda" name="sell_value" v-model="value.sell_value" />
            </div>
            <div class="col-md-3">
                <floating-label-currency-component label="Valor do Sinal" name="sell_signal" v-model="value.sell_signal" />
            </div>
            <div class="col-md-3">
                <floating-label-date-component name="sell_pccv_date" label="Data do CPCV" v-model="value.sell_pccv_date" />
            </div>
            <!-- <div class="col-md-4">
                <floating-label-currency-component label="Reforço do Sinal" name="sell_reinforcement_signal" v-model="value.sell_reinforcement_signal" />
            </div>
            <div class="col-md-4">
                <floating-label-date-component name="sell_reinforcement_date" label="Data do Reforço"
                    v-model="value.sell_reinforcement_date"/>
            </div> -->
            <div class="col-md-3">
                <floating-label-date-component name="sell_writing_deadline" label="Prazo da Escritura" v-model="value.sell_writing_deadline" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <drop-down-component name="sell_payment_type" label="Meio de Pagamento do Sinal" v-model="value.sell_payment_type" :items="paymentTypes" />
            </div>
        </div>
        <div class="row px-2" v-if="value.sell_payment_type !== 'BANK_TRANSFER'">
            <div class="col-md-4">
                <floating-label-component name="sell_payment_number" label="Número" v-model="value.sell_payment_number" />
            </div>
            <div class="col-md-4">
                <floating-label-date-component name="sell_payment_date" label="Data" v-model="value.sell_payment_date" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_payment_bank" label="Banco" v-model="value.sell_payment_bank" />
            </div>
        </div>
        <div class="row px-2" v-else>
            <div class="col-12">
                <label>Dados do Comprador</label>
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_buyer_iban" label="IBAN do Comprador" v-model="value.sell_buyer_iban" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_buyer_name" label="Titular da Conta" v-model="value.sell_buyer_name" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_buyer_bank" label="Banco" v-model="value.sell_buyer_bank" />
            </div>
            <div class="col-12">
                <label>Dados do Vendedor</label>
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_seller_iban" label="IBAN do Vendedor" v-model="value.sell_seller_iban" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_seller_name" label="Titular da Conta" v-model="value.sell_seller_name" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="sell_seller_bank" label="Banco" v-model="value.sell_seller_bank" />
            </div>
        </div>
    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: Object,
        paymentTypes: Array,
    },
    methods: {

    }
}
</script>

