<template>
    <card-component v-if="value" title="Condição Resolutiva">
        <div class="row">
            <div class="col-12">
                <option-component name="resolution_type" :options="yesNoAnotherTypes"
                    v-model="value.resolution_type" />
            </div>
            <div class="col-12 mt-3" v-if="this.isObsVisible()">
                <floating-label-component name="resolution_observation"
                    label="Outro: Descrever" v-model="value.resolution_observation" />
            </div>
        </div>

    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: { type: Object, required: true },
        yesNoAnotherTypes: Array,
    },
    data() {
        return {
        }
    },
    methods: {
        isObsVisible() {
            const c = this.value.resolution_type;
            if (c === undefined) {
                return false;
            }
            return c === 'ANOTHER';
        },
    }
}
</script>

