

<template>
    <div class="form-floating mb-3">
        <input type="hidden" :name="name" :value="cleanValue" />
        <input v-bind="$attrs" type="text" class="form-control" :placeholder="label" v-model="formattedValue"
            @change="onChange($event)" v-mask="{ 'alias': 'currency' }" />
        <label>{{ label }}</label>
    </div>
</template>

<script>
export default {
    mounted() {
        this.cleanValue = this.value;
        this.formattedValue = this.value;
    },
    props: {
        name: {
            type: String,
        },
        value: {
            type: String|Number,
        },
        label: {
            type: String,
        },
    },
    data() {
        return {
            cleanValue: 0,
            formattedValue: 0,
        };
    },
    methods: {
        onChange(event) {
            const value = event.target.value;
            this.cleanValue = this.removeFormat(value);
            this.$emit('input', this.cleanValue);
        },
        removeFormat(value) {
            return value.replaceAll('€','').replaceAll(',', '').replaceAll(' ', '');
        }
    }

}
</script>

