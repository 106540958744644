<template>
    <div class="marketing-template-categories-list">
        <!-- <div class="row">
            <div class="col">
                Selecionado:
                {{ this.selectedCategory?.name }}
                {{ this.selectedModel?.name }}
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <ul class="wow-marketing-categories">
                    <li v-for="category in categories" @click="selectCategory(category)" v-bind:class="(category.id == selectedCategory?.id) ? 'selected' : ''">
                        {{ category.name }}
                    </li>
                </ul>
            </div>
            <div class="col-md-10" v-if="selectedCategory">
                <ul class="wow-marketing-templates">
                    <li class="card" v-for="template in getTemplates()" @click="selectTemplate(template)">
                        <div class="card-img">
                            <!-- <file-preview-component :file="template.file" /> -->
                            <img :src="template.file.url" style="max-height: 250px;" class="img-fluid" v-if="template.file && template.file.mime_type.startsWith('image/')">
                            <span v-if="template.file && template.file.mime_type.startsWith('application/')">
                                {{ template.file.name }}
                            </span>
                        </div>
                        <div class="card-body">
                            {{ template.name }}
                        </div>
                        <!-- <div class="row">
                            <div class="col-6" v-if="template.file">
                                <file-preview-component :file="template.file" />
                            </div>
                            <div class="col-6" v-if="template.back_file">
                                <file-preview-component :file="template.back_file" />
                            </div>
                        </div> -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        templates: Array,
        categories: Array,
        models: Array,
    },
    data() {
        return {
            selectedCategory: null,
            // selectedModel: null,
        }
    },
    methods: {
        selectCategory(category) {
            // this.selectedModel = null;
            this.selectedCategory = category;
        },
        // selectModel(model) {
        //     if (this.selectedModel?.id === model.id) {
        //         this.selectedModel = null;
        //     } else {
        //         this.selectedModel = model;
        //     }
        // },
        selectTemplate(template) {
            this.$emit('change', template);
        },
        getParentModels() {
            return this.models.filter(m => m.parent_id === null);
        },
        // getChildrenModels(model) {
        //     return this.models.filter(m => m.parent_id === model.id);
        // },
        getTemplates() {
            const categoryId = this.selectedCategory?.id;
            // const modelId = this.selectedModel?.id;

            let templates = this.templates.filter(t => t.marketing_category_id === categoryId);
            // if (modelId) {
            //     templates = templates.filter(t => t.marketing_model_id === modelId);
            // }

            return templates
        }
    }
}
</script>
