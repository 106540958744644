<template>
    <section>
        <div class="comments">
        </div>
        <footer class="new-comment">
            <div class="preview">
                <div v-for="(preview, index) in previews" :key="index" class="preview-item">
                    <img v-if="preview.type && preview.type.startsWith('image/')" :src="preview.data" />
                    <p v-else>{{ preview.name }}</p>
                    <span title="Remover Documento" class="action" @click="removeFile(index)"><svg-vue icon="delete"/></span>
                </div>
            </div>
            <div class="new-comment-block">
                <div class="new-comment-input">
                    <input name="new_comment_text" type="text" v-model="message" class="form-control" placeholder="Escreva uma mensagem">
                    <div class="actions">
                        <span @click="openFileInput" title="Anexar ficheiro"><svg-vue icon="attachment" color="#0F2236"/></span>
                    </div>
                </div>
                <input type="file" name="new_comment_files[]" ref="file" v-on:change="handleFileUpload()" accept=".jpeg, .jpg, .png, .doc, .docx, .pdf" multiple />
            </div>
            <p>Este comentário inicial juntamente com qualquer documento(s) selecionado aqui será criado no processo de criação deste pedido.</p>
        </footer>
    </section>
</template>

<script>

export default {
    mounted() {
    },
    props: {
    },
    data() {
        return {
            message: '',
            files: [],
            previews: [],
        };
    },
    methods: {
        openFileInput() {
            this.$refs.file.click();
        },
        handleFileUpload(){
            this.files = Array.from(this.$refs.file.files);
            this.previews = this.files.map(file => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve({ data: e.target.result, type: file.type, name: file.name });
                    reader.onerror = (e) => reject(e);
                    reader.readAsDataURL(file);
                });
            });
            Promise.all(this.previews).then(values => {
                this.previews = values;
            });
            // const newFiles = Array.from(this.$refs.file.files);
            // const newPreviews = newFiles.map(file => {
            //     return new Promise((resolve, reject) => {
            //         const reader = new FileReader();
            //         reader.onload = (e) => resolve({ data: e.target.result, type: file.type, name: file.name });
            //         reader.onerror = (e) => reject(e);
            //         reader.readAsDataURL(file);
            //     });
            // });
            // Promise.all(newPreviews).then(values => {
            //     this.files = [...this.files, ...newFiles];
            //     this.previews = [...this.previews, ...values];

            //     console.log(this.files);
            //     console.log(this.$refs.file.files);
            // });
        },
        removeFile(index) {
            this.files.splice(index, 1);
            this.previews.splice(index, 1);
        }
    }
}
</script>
