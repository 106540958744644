<template>
    <div class="row">
        <div class="col">
            <card-component title="Selecione um Template" v-if="!this.order.marketing_template_id">
                <marketing-order-template-picker-component :templates="templates" :categories="categories"
                    @change="selectTemplate($event)" />
            </card-component>

            <card-component title="Informações do Pedido" v-if="this.order.marketing_template_id">
                <div class="row">
                    <div class="col-md-6">
                        <!-- <div @click="removeTemplate()" v-if="this.canChangeTemplate">x</div> -->
                        <floating-label-component label="Template" readonly :value="getTemplate()?.name" />
                        <input type="hidden" name="marketing_template_id" :value="order.marketing_template_id">
                    </div>
                    <div class="col-md-6">
                        <drop-down-component name="agency_id" placeholder="Agência" :value="order.agency_id"
                            :items="this.agencies" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <floating-label-component name="client_name" label="Nome do Agente" v-if="order.client_name != ''" v-model="order.client_name" />
                        <floating-label-component name="client_name" label="Nome do Agente" v-else v-model="user.name" />
                    </div>
                    <div class="col-md-4">
                        <floating-label-component name="client_email" label="Email" v-if="order.client_email != ''" v-model="order.client_email" />
                        <floating-label-component name="client_email" label="Email" v-else v-model="user.email" />
                    </div>
                    <div class="col-md-4">
                        <floating-label-component name="client_phone" label="Telemóvel" v-if="order.client_phone != ''" v-model="order.client_phone" />
                        <floating-label-component name="client_phone" label="Telemóvel" v-else v-model="user.tlm" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <floating-label-component name="comment" label="Observações" v-model="order.comment" />
                    </div>
                </div>
            </card-component>

            <card-component title="Pré-visualização de Template" v-if="this.order.marketing_template_id">
                <div class="row">
                    <div class="col-6" v-if="getTemplate()?.file">
                        <file-upload-component label="Frente" icon="pay_slip" :value="getTemplate()?.file?.id"
                            :readonly="true" />
                    </div>
                    <div class="col-6" v-if="getTemplate()?.back_file">
                        <file-upload-component label="Verso" icon="pay_slip" :value="getTemplate()?.back_file.id"
                            :readonly="true" />
                    </div>
                </div>
            </card-component>

            <card-component title="Designer Work" v-if="this.order.file_id && !this.isDesigner">
                <div class="row">
                    <div class="col-6">
                        <file-upload-component label="Frente" icon="pay_slip" :value="order.file_id" :readonly="true" />
                    </div>
                    <div class="col-6" v-if="order.file_back_id">
                        <file-upload-component label="Verso" icon="pay_slip" :value="order.file_back_id"
                            :readonly="true" />
                    </div>
                </div>
            </card-component>

            <card-component title="Designer Input" v-if="this.isDesigner">
                <div class="row" v-if="this.designerCanUpload">
                    <div class="col-6">
                        <file-upload-component label="Frente" name="file_id" icon="pay_slip" :value="order.file_id" />
                    </div>
                    <div class="col-6" v-if="getTemplate()?.has_back_file === 'YES'">
                        <file-upload-component label="Verso" name="file_back_id" icon="pay_slip"
                            :value="order.file_back_id" />
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-6">
                        <file-upload-component label="Frente" name="file_id" icon="pay_slip" :value="order.file_id"
                            :readonly="true" />
                    </div>
                    <div class="col-6" v-if="getTemplate()?.has_back_file === 'YES'">
                        <file-upload-component label="Verso" name="file_back_id" icon="pay_slip"
                            :value="order.file_back_id" :readonly="true" />
                    </div>
                </div>
            </card-component>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        originalOrder: Object,
        agencies: Array,
        templates: Array,
        categories: Array,
        // models: Array,
        userData: Object,
        isDesigner: {
            type: Boolean,
            default: false,
        },
        designerCanUpload: {
            type: Boolean,
            default: false,
        },
        canChangeTemplate: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            order: this.originalOrder,
            user: this.userData,
            isReadOnly: this.isDe,
        }
    },
    methods: {
        selectTemplate(template) {
            if (template?.id) {
                this.order = {
                    ...this.order,
                    marketing_template_id: template.id
                }
            }
        },
        getTemplate() {
            return this.templates.find(t => t.id === this.order.marketing_template_id);
        },
        removeTemplate() {
            this.order.marketing_template_id = null;

        }
    },
}
</script>
