<template>
    <div id="contract-component">
        <div class="row">
            <div class="col">
                <card-component title="Informações do Contrato">
                    <div class="row">
                        <div class="col-md-3">
                            <drop-down-component name="agency_id" placeholder="Agência" :value="contract.agency_id"
                                :items="this.agencies" @onChange="selectAgency($event)" />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="agent_id" label="Agente" :value="contract.agent_id"
                                :items="this.getUsersForField('agent_id')" allow-empty />
                        </div>
                        <div class="col-md-3" v-if="contract.id">
                            <drop-down-component name="manager_id" label="Responsável por elaboração de contrato"
                                :value="contract.manager_id" :items="this.getUsersForField('manager_id')" allow-empty />
                        </div>
                        <div class="col-md-3">
                            <floating-label-component name="property_cmi" label="ID do Imóvel"
                                :mask="{ 'regex': '[0-9]{9}-[0-9]{1,10}' }" v-model="contract.property_cmi" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <option-component name="client_type" label="Tipo de Comprador"
                                v-model="contract.client_type" :options="clientTypes" />
                        </div>
                        <div class="col-md-6">
                            <option-component name="business_type" label="Tipo de Negócio"
                                v-model="contract.business_type" :options="businessTypes" />
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-12">
                            <floating-label-text-area-component name="observation"
                                label="Observações" v-model="contract.observation" />
                        </div>
                        <!-- <div class="col-md-4">
                            <files-upload innerLabel="Documentos" name="documents" v-model="contract.file_id"
                                :file-ids="fileIds"
                                @fileRemoved="removeFile"
                                @onChange="fileChanged($event)" />
                        </div> -->
                    </div>
                </card-component>
            </div>
        </div>
        <div v-if="contract.client_type == 'SINGULAR'" class="row">
            <div class="col">
                <div v-for="(client, i) in clients">
                    <div style="position: relative;">
                        <span @click="removeClient(i)"
                            style="position: absolute;z-index: 1;top: 13px;right: 1rem;"><svg-vue
                                icon="delete" /></span>
                        <contract-singular-person-component :name="'clients[' + i + ']'" :value="client"
                            :civil-status-types="civilStatusTypes" :marriage-regime-types="marriageRegimeTypes"
                            :client-document-types="clientDocumentTypes" :client-languages="clientLanguages" />
                    </div>
                </div>
                <div role="group" class="btn-group w-100 mb-4">
                    <button type="button" class="btn btn-secondary" @click="addClient()">
                        <svg-vue icon="plus" /> Cliente Adicional
                    </button>
                </div>
            </div>
        </div>
        <div v-if="contract.client_type == 'COLLECTIVE'" class="row">
            <div class="col">
                <contract-company-component v-model="contract" :civil-status-types="civilStatusTypes"
                    :marriage-regime-types="marriageRegimeTypes" :client-document-types="clientDocumentTypes"
                    :client-languages="clientLanguages" />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <contract-rent-condition-component v-if="contract.business_type === 'RENT'" v-model="contract"
                    :deal-types="dealTypes" :renovation-types="renovationTypes" />

                <contract-sell-condition-component v-if="contract.business_type === 'BUY_AND_SELL'" v-model="contract"
                    :payment-types="paymentTypes" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <contract-characteristics-component v-model="contract" :equipment-types="equipmentTypes"
                    :furniture-types="furnitureTypes" :characteristic-types="characteristicTypes" />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <contract-resolution-component v-model="contract" :yes-no-another-types="yesNoAnotherTypes" />
            </div>
        </div>
        <div v-if="contract.business_type == 'RENT'" class="row">
            <div class="col">
                <contract-guarantor-component v-model="contract" :yes-no-types="yesNoTypes"
                    :civil-status-types="civilStatusTypes" :marriage-regime-types="marriageRegimeTypes"
                    :client-document-types="clientDocumentTypes" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        this.contract = { ...this.originalContract };
        this.clients = this.contract?.clients || [];
        if (this.clients.length === 0) {
            this.addClient();
        }

        this.selectedAgency = this.contract.agency_id ? this.agencies.find(a => a.id === this.contract.agency_id) : this.agencies[0];

        nextTick(() => {
            if (this.assignManager) {
                const content = $('#contract-component')[0];
                const tags = ["input", "button", "textarea", "select"];
                tags.forEach(tagName => {
                    const nodes = content.getElementsByTagName(tagName);
                    for (let i = 0; i < nodes.length; i++) {
                        if (nodes[i].name === 'manager_id') {
                            continue;
                        }
                        nodes[i].disabled = true;
                        nodes[i].tabIndex = -1;
                    }
                });

            }
        })
    },
    props: {
        originalContract: Object,
        assignManager: {
            type: Boolean,
            default: false,
        },
        clientTypes: Array,
        clientLanguages: Array,
        businessTypes: Array,
        equipmentTypes: Array,
        furnitureTypes: Array,
        civilStatusTypes: Array,
        marriageRegimeTypes: Array,
        clientDocumentTypes: Array,
        dealTypes: Array,
        renovationTypes: Array,
        yesNoAnotherTypes: Array,
        yesNoTypes: Array,
        paymentTypes: Array,
        characteristicTypes: Array,
    },
    data() {
        return {
            agencies: window.agencies,
            contract: {},
            clients: [],
            selectedAgency: null,
        }
    },
    computed: {
        fileIds() {
            if (!this.contract.files || this.contract.files.length === 0) {
                return [];
            }
            return this.contract.files.map(file => file.id);
        }
    },
    methods: {
        selectAgency: function (id) {
            this.selectedAgency = this.agencies.find(a => a.id === id);
        },
        addClient: function () {
            this.clients.push({
                name: '',
            });
        },
        removeClient: function (i) {
            if (this.clients.length === 1) {
                return;
            }
            this.clients.splice(i, 1);
        },
        getUsersForField: function (field) {
            if (!this.selectedAgency) {
                return [];
            }

            const roleIds = window.modelConfiguration?.Contract && window.modelConfiguration.Contract[field] || []
            const roles = Object.values(this.selectedAgency.roles).filter(r => roleIds.find(id => id === r.id));
            const users = roles.map(r => r.users).flat()
            let result = [];
            const map = new Map();
            for (const item of users) {
                if (!map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name
                    });
                }
            }
            result.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

            return result;
        },
        fileChanged(event) {
            for (let i = 0; i < event.target.files.length; i++) { // Loop through all selected files
                this.fileData.push(event.target.files[i]); // Add each file to the fileData array
            }
        },
        removeFile(fileId) {
            const data = {
                contract_id: this.contract.id,
                file_id: fileId
            }
            axios.post(window.moduleContractFileUrlDelete, data)
                .then(response => {
                    toastr.success("Documento removido com sucesso.");
                }).catch(e => {
                    toastr.error("Erro ao remover Documento.");
                }).finally(e => {
                })

        },
        createInstance() {
            const formData = new FormData();
            this.fileData.forEach((file, index) => {
                formData.append(`file${index + 1}`, file); // Append each file to the formData
            });
        },
    }
}
</script>

