<template>
    <div class="position-relative">
        <input type="text" :name="name" :value="currentItem ? currentItem.id : ''" hidden>
        <div class="form-floating wow-search-autocomplete">
            <span class="form-control" @click="toggleSearch">
                {{ currentItem ? getOptionValue(currentItem) : '' }}
                <input v-if="isSearchOpen" ref="search" type="text" id="input_id" @input="debounceSearch"
                    @blur="toggleSearch">
            </span>
            <label>{{ placeholder }}</label>
            <span v-if="!isReadOnly() && currentItem" @click="removeItem">
                <svg-vue icon="block" class="remove-selection" />
            </span>
            <span v-if="isNewVisible()" @click="addItem">
                + Criar Novo
            </span>
        </div>
        <div v-if="isSearchOpen && !loading && !queryIsValid()" class="px-2">
            {{ selectplaceholder }}
        </div>
        <ul class="wow-search-autocomplete-results" v-if="(isSearchOpen && (loading || queryIsValid()))">
            <!-- v-if="isSearchOpen" -->
            <li v-if="loading">A pesquisar...</li>
            <li v-if="!loading && queryIsValid() && !results.length">
                Sem resultados
            </li>
            <li v-if="!loading && queryIsValid() && results.length" v-for="result in results"
                @mousedown="selectItem(result)">{{ getResultOptionValue(result) }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    mounted() {
        if (this.value) {
            this.loadById(this.value);
        }
    },
    props: {
        test: Function,
        type: {
            type: String,
            required: true,
            option: ['']
        },
        displayFields: {
            type: Array,
            default() {
                return ['name'];
            }
        },
        resultFields: {
            type: Array,
            default() {
                return ['name'];
            }
        },
        name: String,
        value: {
            required: true,
        },
        placeholder: String,
        selectplaceholder: {
            type: String,
            default: 'Introduza 2 ou mais caracteres.',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            isSearchOpen: false,
            showSlot: false,
            debounce: null,
            currentItem: null,
            query: '',
            results: [],
        }
    },
    watch: {
        // whenever question changes, this function will run
        value(newValue, oldValue) {
            if (newValue) {
                this.loadById(this.value);
            }
        }
    },
    methods: {
        toggleSearch: function () {
            if (this.isReadOnly()) {
                return;
            }

            if (!this.isSearchOpen && this.currentItem) {
                return;
            }

            this.reset();
            this.isSearchOpen = !this.isSearchOpen;

            if (this.isSearchOpen) {
                // Timeout because the element is not rendered, also tested with visibility of the element, but cannot focus an hidden element 
                setTimeout(() => this.$refs.search.focus(), 200)
            }
        },
        isNewVisible: function () {
            return this.type === 'client' && !this.isReadOnly() && !this.currentItem && !this.isSearchOpen;
        },
        reset: function () {
            this.results = [];
            this.query = '';
        },
        queryIsValid: function () {
            return this.query.length >= 1;
        },
        getOptionValue: function (option) {
            const values = this.displayFields.map(field => option[field]);
            return values.join(' - ');
        },
        getResultOptionValue: function (option) {
            const values = this.resultFields.map(field => option[field]);
            return values.join(' - ');
        },
        isReadOnly: function () {
            return this.readOnly;
        },
        removeItem: function () {
            this.setCurrentItem(null);
        },
        addItem: function () {
            window.bus.$emit('open-' + this.type, '');
            window.bus.$on('created-' + this.type, (data) => {
                if (data) {
                    this.setCurrentItem(data);
                }
                window.bus.$off('created-' + this.type);
            });
        },
        selectItem: function (result) {
            this.setCurrentItem(result);
        },
        setCurrentItem: function (item) {
            this.currentItem = item
            this.$emit('input', item?.id);
        },
        loadById: function (id) {
            this.loading = true;
            axios.post(window.searchUrl, { id, model: this.type })
                .then(response => {
                    this.loading = false;
                    if (response.status === 200) {
                        this.setCurrentItem(response.data);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.setCurrentItem(null);
                });
        },
        debounceSearch: function (event) {
            this.query = event.target.value;
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                if (!this.queryIsValid()) {
                    return
                }
                this.loading = true;
                axios.post(window.searchUrl, { term: this.query, model: this.type })
                    .then(response => {
                        this.loading = false;
                        if (response.status === 200) {
                            this.results = response.data;
                        }
                    }).catch(e => {
                        this.loading = false;
                        this.results = [];
                        event.target.value = ''
                        this.query = ''
                    });
            }, 300)
        },
    }
}
</script>
