<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <floating-label-component name="client_name" label="Cliente" v-model="credit.client_name" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="client_phone" label="Telemóvel" v-model="credit.client_phone" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="client_email" label="E-mail" v-model="credit.client_email" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <drop-down-component name="agency_id" placeholder="Agência" v-model="credit.agency_id"
                    :items="this.agencies" @onChange="selectAgency($event)" />
            </div>
            <div class="col-md-4">
                <drop-down-component name="agent_id" placeholder="Agente" v-model="credit.agent_id"
                    :items="this.getUsersForField('agent_id')" />
            </div>
            <div class="col-md-4">
                <drop-down-component name="manager_id" placeholder="Intermediário de Crédito"
                    v-model="credit.manager_id" :items="this.getUsersForField('manager_id', 'Indiferente')" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-3">
                    <option-component name="with_house" label="Imóvel escolhido?" v-model="credit.with_house"
                        :options="this.booleanEnum" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3">
                    <option-component name="need_to_sell_house" label="Precisa de vender Imóvel?"
                        v-model="credit.need_to_sell_house" :options="this.booleanEnum" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <floating-label-text-area-component name="observations" label="Observações"
                    placeholder="Observações" rows="3" v-model="credit.observations" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.credit = { ...this.oCredit };
        this.agencies = window.agencies;
        this.selectedAgency = this.credit.agency_id ? this.agencies.find(a => a.id === this.credit.agency_id) : this.agencies[0]
    },
    props: {
        oCredit: Object,
        booleanEnum: Array,
    },
    data() {
        return {
            credit: {},
            agencies: [],
            selectedAgency: null,
        }
    },
    methods: {
        selectAgency: function (id) {
            this.selectedAgency = this.agencies.find(a => a.id === id);
        },
        getUsersForField: function (field, emptyLabel = '') {
            if (!this.selectedAgency) {
                return [];
            }

            const roleIds = window.modelConfiguration?.Credit && window.modelConfiguration.Credit[field] || []
            const roles = Object.values(this.selectedAgency.roles).filter(r => roleIds.find(id => id === r.id));
            const users = roles.map(r => r.users).flat()

            let result = [];
            const map = new Map();
            for (const item of users) {
                if (!map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name
                    });
                }
            }
            result.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            result = [
                {
                    id: null,
                    name: emptyLabel
                },
                ...result,
            ]
            return result;
        },
    }
}
</script>
