
<template>
    <card-component title="Condições do Negócio">
        <div class="row">
            <div class="col-md-6">
                <drop-down-component name="rent_type" placeholder="Tipo de Contrato" v-model="value.rent_type"
                    :items="dealTypes" />
            </div>
            <div class="col-md-6">
                <floating-label-currency-component label="Valor" name="rent_value" v-model="value.rent_value" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <floating-label-component name="rent_repeat_payment" type="number" label="Número de Rendas a Entregar"
                    v-model="value.rent_repeat_payment" />
            </div>
            <div class="col-md-3">
                <floating-label-currency-component label="Caução" name="rent_collateral" v-model="value.rent_collateral" />
            </div>
            <div class="col-md-3">
                <floating-label-date-component name="rent_contract_start" label="Data de Início do Contrato"
                    v-model="value.rent_contract_start" />
            </div>
            <div class="col-md-3">
                <floating-label-date-component name="rent_contract_deadline" label="Prazo do Contrato"
                    v-model="value.rent_contract_deadline" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <floating-label-component name="rent_owner_iban" label="IBAN do Proprietário"
                    v-model="value.rent_owner_iban" />
            </div>
            <div class="col-md-3">
                <floating-label-component name="rent_owner_bank" label="Banco do Proprietário"
                    v-model="value.rent_owner_bank" />
            </div>
            <div class="col-md-3">
                <drop-down-component name="rent_renovation_type" label="Tipo de Renovação"
                    v-model="value.rent_renovation_type" :items="renovationTypes" />
            </div>
            <div class="col-md-3">
                <floating-label-date-component name="rent_renovation_deadline" label="Prazo de Renovação"
                    v-model="value.rent_renovation_deadline" />
            </div>
        </div>

    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: Object,
        dealTypes: Array,
        renovationTypes: Array,
    },
    methods: {

    }
}
</script>

