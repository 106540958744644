class UserService {
    hasRole(role) {
        const { roles } = window.currentUser;
        return !!roles.find(
            (r) => r.id === role || r.name === role.toLowerCase()
        );
    }

    hasAnyRole(roles) {
        return roles.some((r) => this.hasRole(r));
    }
}

export default new UserService();
