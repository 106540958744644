<template>
    <div>
        <div class="d-flex justify-content-between">
            <label v-if="label">{{ label }}</label>
            <div v-if="showSlot()">
                <slot></slot>
            </div>
        </div>
        <div class="btn-group w-100" role="group">
            <template v-for="option in options">
                <input type="radio" class="btn-check" :name="name" :id="option.id + '_' + randomNumber"
                    autocomplete="off" :value="option.value" :checked="option.value == value" @change="onChange($event)" />
                <label class="btn btn-outline-secondary" :for="option.id + '_' + randomNumber">{{ option.name }}</label>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.randomNumber = Math.floor(Math.random() * 10000000);
    },
    props: {
        name: {
            type: String,
        },
        label: {
            type: String,
        },
        value: {
            type: String | Number,
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            randomNumber: null,
        };
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
            this.$emit('onChange', event.target.value);
        },
        showSlot() {
            return !!this.$slots;
        }
    }
}
</script>

