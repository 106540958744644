class ModelRolesService {
    getRoles(model, field) {
        try {
            return window.modelConfiguration[model][field];
        } catch (e) {
            console.error(
                "Model Roles Configuration - cannot find required model/field - ",
                model,
                field
            );
        }
        return [];
    }
}

export default new ModelRolesService();
