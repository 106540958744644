<template>
    <div class="row form-row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-6">
                    <drop-down-component label="Cargo" v-model="selectedRoleId" :items="this.getAvailableRoles()" />
                </div>
                <div class="col-6">
                    <button type="button" class="btn btn-success" @click="addRole()" v-show="selectedRoleId">+
                        Cargo</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul>
                        <li v-for="role in currentRoles">
                            {{ role.title }}<span @click="removeRole(role)">×</span>
                            <input type="text" class="d-none" name="roles[]" :value="role.id" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.currentRoles = this.roles.filter(r => this.userRoleIds.find(rId => rId === r.id));
    },
    props: {
        userRoleIds: Array,
        roles: Array,
    },
    data() {
        return {
            currentRoles: [],
            selectedRoleId: null,
        }
    },
    methods: {
        getAvailableRoles() {
            return [
                {
                    id: null,
                    name: ''
                },
                ...this.roles.filter(r => !this.currentRoles.find(c => c.id === r.id)).map(f => ({id: f.id, name: f.title}))];
        },
        getRole: function (id) {
            return this.roles.find(r => r.id === id)
        },
        getSelectedRole: function () {
            return this.getRole(this.selectedRoleId)
        },
        addRole: function () {
            this.currentRoles.push(this.getSelectedRole());
            this.selectedRoleId = null;
        },
        removeRole: function (role) {
            this.selectedRoleId = null;
            this.currentRoles = this.currentRoles.filter(r => r.id !== role.id);
        },
    }
}
</script>

<style>
.form-row {
    border: 1px solid #e2e2e2;
    margin: 10px;
    padding: 20px;
    background: #f2f2f2;
}
</style>