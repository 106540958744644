<template>
    <card-component v-if="value" title="Fiador">
        <option-component name="guarantor_enabled" v-model="value.guarantor_enabled" :options="yesNoTypes" />
        <div v-if="value.guarantor_enabled === 'YES'">
            <div class="row mt-3">
                <div class="col-md-6">
                    <floating-label-component name="guarantor_name" label="Nome Completo do Fiador"
                        v-model="value.guarantor_name" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_nationality" label="Nacionalidade"
                        v-model="value.guarantor_nationality" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_birth_place" label="Local de Nascimento"
                        v-model="value.guarantor_birth_place" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <floating-label-component name="guarantor_address" label="Morada"
                        v-model="value.guarantor_address" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_zipcode" label="Código Postal"
                        v-model="value.guarantor_zipcode" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_city" label="Localidade"
                        v-model="value.guarantor_city" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <drop-down-component name="guarantor_civil_state" label="Estado Civil"
                        v-model="value.guarantor_civil_state" :items="civilStatusTypes" />
                </div>
                <div class="col-md-3" v-if="this.isMarriageRegimeVisible()">
                    <drop-down-component name="guarantor_marriage_regime" label="Regime de Casamento"
                        v-model="value.guarantor_marriage_regime" :items="marriageRegimeTypes" />
                </div>
                <div class="col-md-3">
                    <floating-label-date-component name="guarantor_birth_date" label="Data de Nascimento"
                        v-model="value.guarantor_birth_date" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_profession" label="Profissão"
                        v-model="value.guarantor_profession" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <drop-down-component name="guarantor_document_type" label="Documento de Identificação"
                        v-model="value.guarantor_document_type" :items="clientDocumentTypes" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_document_number" label="Nº do Documento"
                        v-model="value.guarantor_document_number" />
                </div>
                <div class="col-md-3">
                    <floating-label-date-component name="guarantor_document_expiration_date" label="Data de Validade"
                        v-model="value.guarantor_document_expiration_date" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="guarantor_document_emitter" label="Entidade Emissora"
                        v-model="value.guarantor_document_emitter" />
                </div>
            </div>
        </div>
    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        yesNoTypes: Array,
        civilStatusTypes: Array,
        marriageRegimeTypes: Array,
        clientDocumentTypes: Array,
    },
    data() {
        return {
            config: { ...window.config },
        }
    },
    methods: {
        isMarriageRegimeVisible() {
            return this.value.guarantor_civil_state === 'MARRIED';
        }
    }
}

</script>

