<template>
    <div>
        <!-- Modal -->
        <div v-if="visible" class="modal fade show" tabindex="-1" aria-modal="true" role="dialog" style="display:block">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" @click="close()" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <slot ref="slot"></slot>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="close()" :class="'btn btn-' + variant">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    created() {
        window.bus.$on('open-' + this.type, () => {
            this.open();
        });
    },
    props: {
        variant: String,
        type: {
            type: String,
            default: 'client',
        }
    },
    data() {
        return {
            visible: false,
            item: null,
            form: null,
            form: null,
            errors: [],
        }
    },
    methods: {
        reset() {
            this.visible = false;
            this.item = null;
            this.cleanErrors();
        },
        close(user) {
            if (user) {
                window.bus.$emit('created-' + this.type, user);
            } else {
                window.bus.$emit('created-' + this.type, null);
            }
            this.reset();
        },
        open() {
            this.visible = true;

            setTimeout(() => {
                this.listenForm();
            }, 100);
        },
        listenForm() {
            const formId = this.$slots.default[0].data.attrs.id
            this.form = $('#' + formId)[0];
            if (!this.form) {
                console.error('could not find the form');
                return ;
            }

            // Make form visible
            if (this.form.style.visibility !== 'visible') {
                this.form.style.visibility = 'visible';
            }

            this.form.addEventListener('submit', e => {
                e.preventDefault();
                this.submitForm(e.target);
            });
        },
        cleanErrors() {
            this.errors = [];
            const inputs = $(this.form).find('input');
            for (const input of inputs) {
                $(input).removeClass('is-invalid');
            }

            const divErrors = $(this.form).find('div.invalid-feedback');
            for (const div of divErrors) {
                $(div).remove();
            }
        },
        showErrors() {
            const inputs = $(this.form).find('input');
            for (const error in this.errors) {
                const errorMessage = this.errors[error].toString();
                for (const input of inputs) {
                    if (input.name === error) {
                        $(input).addClass('is-invalid').after($('<div>').text(errorMessage).addClass('invalid-feedback'));
                    }
                }
            }
        },
        submitForm(form) {
            this.errors = [];
            this.form = form;
            const url = form.action;
            const serializedData = $(form).serializeArray();
            const data = {};
            serializedData.forEach((item) => {
                data[item.name] = item.value
            });

            this.cleanErrors();

            axios.post(url, data)
                .then(response => {
                    this.close(response.data)
                }).catch(e => {
                    if (e.response.status == 422) {
                        this.errors = e.response.data.errors;
                        this.showErrors();
                    }
                }).finally(e => {
                });
        },
    }
}
</script>
