/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

// import Alpine from 'alpinejs';

// window.Alpine = Alpine;

// Alpine.start();

window.Vue = require("vue").default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component(
    "selector-component",
    require("./components/SelectorComponent.vue").default
);
Vue.component(
    "permission-management",
    require("./components/agency/PermissionManagementComponent.vue").default
);
Vue.component(
    "files-upload",
    require("./components/form/FilesUploadComponent.vue").default
);
Vue.component(
    "file-upload-component",
    require("./components/form/FileUploadComponent.vue").default
);
Vue.component(
    "file-preview-component",
    require("./components/form/FilePreviewComponent.vue").default
);
Vue.component(
    "user-agencies",
    require("./components/user/UserAgenciesComponent.vue").default
);
Vue.component(
    "user-roles",
    require("./components/user/UserRolesComponent.vue").default
);
Vue.component(
    "search-autocomplete",
    require("./components/search/SearchAutocompleteComponent.vue").default
);
Vue.component(
    "credit-clients-component",
    require("./components/credit/CreditClientsComponent.vue").default
);
Vue.component(
    "credit-proposals-component",
    require("./components/credit/CreditProposalsComponent.vue").default
);
Vue.component(
    "credit-detail-component",
    require("./components/credit/CreditDetailComponent.vue").default
);
Vue.component(
    "credit-stats-component",
    require("./components/credit/CreditStatsComponent.vue").default
);
Vue.component(
    "property-component",
    require("./components/property/PropertyComponent.vue").default
);
Vue.component(
    "property-documents-component",
    require("./components/property/PropertyDocumentsComponent.vue").default
);
Vue.component(
    "property-stats-component",
    require("./components/property/PropertyStatsComponent.vue").default
);
Vue.component(
    "invoice-detail-component",
    require("./components/invoice/InvoiceDetailComponent.vue").default
);
Vue.component(
    "invoice-buyer-external-component",
    require("./components/invoice/InvoiceBuyerExternalComponent.vue").default
);
Vue.component(
    "invoice-buyer-internal-component",
    require("./components/invoice/InvoiceBuyerInternalComponent.vue").default
);
Vue.component(
    "invoice-clients-component",
    require("./components/invoice/InvoiceClientsComponent.vue").default
);
Vue.component(
    "invoice-invoicing-component",
    require("./components/invoice/InvoiceInvoicesComponent.vue").default
);
Vue.component(
    "invoice-share-component",
    require("./components/invoice/InvoiceShareComponent.vue").default
);
Vue.component(
    "contract-component",
    require("./components/contract/ContractComponent.vue").default
);
Vue.component(
    "contract-singular-person-component",
    require("./components/contract/ContractSingularPersonComponent.vue").default
);
Vue.component(
    "contract-company-component",
    require("./components/contract/ContractCompanyComponent.vue").default
);
Vue.component(
    "contract-characteristics-component",
    require("./components/contract/ContractCharacteristicsComponent.vue")
        .default
);
Vue.component(
    "contract-rent-condition-component",
    require("./components/contract/ContractRentConditionComponent.vue").default
);
Vue.component(
    "contract-sell-condition-component",
    require("./components/contract/ContractSellConditionComponent.vue").default
);
Vue.component(
    "contract-resolution-component",
    require("./components/contract/ContractResolutionComponent.vue").default
);
Vue.component(
    "contract-guarantor-component",
    require("./components/contract/ContractGuarantorComponent.vue").default
);
Vue.component(
    "contract-stats-component",
    require("./components/contract/ContractStatsComponent.vue").default
);
Vue.component(
    "marketing-template-component",
    require("./components/marketing/MarketingTemplateComponent.vue").default
);
Vue.component(
    "marketing-order-template-picker-component",
    require("./components/marketing/MarketingOrderTemplatePickerComponent.vue")
        .default
);
Vue.component(
    "marketing-order-component",
    require("./components/marketing/MarketingOrderComponent.vue").default
);
Vue.component(
    "marketing-order-stats-component",
    require("./components/marketing/MarketingOrderStatsComponent.vue").default
);

Vue.component(
    "juridical-support-component",
    require("./components/juridicalSupport/JuridicalSupportComponent.vue").default
);
Vue.component(
    "juridical-support-stats-component",
    require("./components/juridicalSupport/JuridicalSupportStatsComponent.vue").default
);
Vue.component(
    "event-notification-detail-component",
    require("./components/eventNotification/EventNotificationDetailComponent.vue")
        .default
);
Vue.component(
    "model-configuration-detail-component",
    require("./components/modelConfiguration/ModelConfigurationDetailComponent.vue")
        .default
);
Vue.component(
    "drop-down-component",
    require("./components/form/DropDownComponent.vue").default
);
Vue.component(
    "select-multiple-component",
    require("./components/form/SelectMultipleComponent.vue").default
);
Vue.component(
    "comments-component",
    require("./components/CommentsComponent.vue").default
);
Vue.component(
    "new-comment-component",
    require("./components/NewCommentComponent.vue").default
);
Vue.component(
    "modal-form-component",
    require("./components/ModalFormComponent.vue").default
);
Vue.component(
    "modal-component",
    require("./components/ModalComponent.vue").default
);
Vue.component(
    "card-component",
    require("./components/CardComponent.vue").default
);
Vue.component(
    "change-log-component",
    require("./components/ChangeLogComponent.vue").default
);
Vue.component(
    "global-loading-component",
    require("./components/GlobalLoadingComponent.vue").default
);

Vue.component(
    "option-component",
    require("./components/form/OptionComponent.vue").default
);
Vue.component(
    "floating-label-component",
    require("./components/form/FloatingLabelComponent.vue").default
);
Vue.component(
    "floating-label-currency-component",
    require("./components/form/FloatingLabelCurrencyComponent.vue").default
);
Vue.component(
    "floating-label-percentage-component",
    require("./components/form/FloatingLabelPercentageComponent.vue").default
);
Vue.component(
    "floating-label-date-component",
    require("./components/form/FloatingLabelDateComponent.vue").default
);
Vue.component(
    "floating-label-text-area-component",
    require("./components/form/FloatingLabelTextAreaComponent.vue").default
);
Vue.component(
    "button-component",
    require("./components/form/ButtonComponent.vue").default
);

Vue.component(
    "line-chart-component",
    require("./components/chart/LineChartComponent.vue").default
);
Vue.component(
    "bar-chart-component",
    require("./components/chart/BarChartComponent.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import { BootstrapVue } from "bootstrap-vue"; //Importing

Vue.use(BootstrapVue); // Telling Vue to use this in whole application

// import VueInputMask from 'vue-inputmask' //Importing
// Vue.use(VueInputMask)
import Inputmask from "inputmask";
// Bind to all Vue elements
const VueInputMask = {
    install: function (Vue, options) {
        Vue.directive("mask", {
            bind: function (el, binding) {
                Inputmask(binding.value).mask(el);
            },
        });
    },
};
Vue.use(VueInputMask);

Inputmask.extendAliases({
    currency: {
        suffix: " €",
    },
    spread: {
        alias: "currency", //it inherits all the properties of numeric
        suffix: " %",
        digit: 2,
        max: 100,
        min: 0,
        allowMinus: false,
    },
});

// Bind to all DOM elements
window.addEventListener("load", (event) => {
    Inputmask().mask(document.querySelectorAll("input"));
});

window.Inputmask = Inputmask;

window.moment = require("moment");
window.moment.locale("pt");

window.userService = require("./service/UserService").default;
window.modelRolesService = require("./service/ModelRolesService").default;

// Vue SVG Icons - On migrate to Vue 3 https://laravel-mix.com/extensions/svg-vue
import Vue from "vue";
import { nextTick } from 'vue'
window.nextTick = nextTick;

import SvgVue from "svg-vue";
Vue.use(SvgVue);

Vue.directive("disable-all", {
    // When all the children of the parent component have been updated
    componentUpdated: function (el, binding) {
        if (!binding.value) return;
        const tags = ["input", "button", "textarea", "select"];
        tags.forEach((tagName) => {
            const nodes = el.getElementsByTagName(tagName);
            for (let i = 0; i < nodes.length; i++) {
                nodes[i].disabled = true;
                nodes[i].tabIndex = -1;
            }
        });
    },
});

window.bus = new Vue();

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
);

const app = new Vue({
    el: "#app",
});

// load toastr
window.toastr = require("toastr");
