<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <option-component name="share_type" label="Tipo de Partilha" v-model="invoiceCopy.share_type"
                    :options="shareTypes" />
            </div>
        </div>
        <div class="row" v-if="isTeam()">
            <div class="col-md-4">
                <floating-label-component name="share_agency" label="Agência" v-model="invoiceCopy.share_agency" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="share_agent" label="Agente" v-model="invoiceCopy.share_agent" />
            </div>
            <div class="col-md-4">
                <floating-label-percentage-component name="share_percentage" label="Partilha"
                    v-model="invoiceCopy.share_percentage" />
            </div>
        </div>
        <div class="row" v-if="isInternal()">
            <div class="col-md-6">
                <floating-label-component name="share_agency" label="Agência" v-model="invoiceCopy.share_agency" />
            </div>
            <div class="col-md-6">
                <floating-label-percentage-component name="share_percentage" label="Partilha"
                    v-model="invoiceCopy.share_percentage" />
            </div>
        </div>
        <div class="row" v-if="isExternal()">
            <div class="col-md-4">
                <floating-label-component name="share_agency" label="Agência" v-model="invoiceCopy.share_agency" />
            </div>
            <div class="col-md-4">
                <floating-label-component name="share_agent" label="Agente" v-model="invoiceCopy.share_agent" />
            </div>
            <div class="col-md-4">
                <floating-label-percentage-component name="share_percentage" label="Partilha"
                    v-model="invoiceCopy.share_percentage" />
            </div>
        </div>
        <div class="row border-top pt-2">
            <div class="col-12 mb-3">
                <option-component name="invoice_percentage" label="% A Faturar" v-model="invoiceCopy.invoice_percentage"
                    :options="invoicePercentages" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        shareTypes: {
            type: Array,
            required: true,
        },
        invoicePercentages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            invoiceCopy: { ...this.invoice },
        }
    },
    methods: {
        isTeam() {
            return this.invoiceCopy.share_type === 'TEAM';
        },
        isInternal() {
            return this.invoiceCopy.share_type === 'REMAX';
        },
        isExternal() {
            return this.invoiceCopy.share_type === 'EXTERNAL';
        },
    }

}
</script>
