<template>
    <div>
        <div v-for="(bidder, i) in bidders" :key="i">
            <input type="text" :name="'clients[' + i + '][id]'" :value="bidder.id" hidden />
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <label class="card-subtitle">Proponente <span v-html="i + 1"></span></label>
                        <button class="btn btn-link" type="button" @click="deleteBidder(i)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Proponente"
                            :name="'clients[' + i + '][client_name]'" v-model="bidder.client_name">
                        <label>Proponente</label>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" placeholder="NIF"
                                    :name="'clients[' + i + '][client_nif]'" v-model="bidder.client_nif">
                                <label>NIF</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <floating-label-date-component v-model="bidder.client_birth_date"
                                    :name="'clients[' + i + '][client_birth_date]'" label="Data de Nascimento" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" placeholder="Profissão"
                                    :name="'clients[' + i + '][client_profession]'" v-model="bidder.client_profession">
                                <label>Profissão</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <drop-down-component :name="'clients[' + i + '][client_professional_situation]'"
                        placeholder="Situação Profissional" v-model="bidder.client_professional_situation"
                        :items="professionalItems"></drop-down-component>
                </div>
                <div class="col-md-3">
                    <drop-down-component :name="'clients[' + i + '][client_bond]'" placeholder="Vínculo"
                        :readonly="isBondVisible(bidder.client_professional_situation)" v-model="bidder.client_bond"
                        :items="bondItems"></drop-down-component>
                </div>
                <div class="col-md-3">
                    <floating-label-currency-component label="Remuneração Líquida"
                        :name="'clients[' + i + '][client_net_salary]'" v-model="bidder.client_net_salary" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center border-top py-2">
                        <label class="card-subtitle mb-0">Outros Encargos</label>
                        <span @click="addExpenses(i)">
                            <svg-vue icon="add_full" fill="#8BB8A2" />
                        </span>
                    </div>
                </div>

                <div class="row" v-for="(expense, j) in bidder.expenses" :key="j">
                    <div class="col-11">
                        <div class="row">
                            <input v-if="expense.id" type="text" :name="'clients[' + i + '][expenses][' + j + '][id]'"
                                :value="expense.id" hidden />
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <drop-down-component :name="'clients[' + i + '][expenses][' + j + '][type]'"
                                        placeholder="Tipo" v-model="expense.type"
                                        :items="expenseItems"></drop-down-component>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <floating-label-currency-component label="Valor Mensal"
                                    :name="'clients[' + i + '][expenses][' + j + '][value]'" v-model="expense.value" />
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-link" type="button" @click="deleteExpenses(i, j)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="card-subtitle d-flex border-top pt-2 mt-2">Documentos</label>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <files-upload label="CC" :name="'clients[' + i + '][documents]'" doc-type="PASSPORT_FRONT"
                            :file-ids="getDocumentIds(bidder, 'PASSPORT_FRONT')"
                            :inner-label="fileCategories['PASSPORT_FRONT']" icon="citizen_card"
                            @fileSelected="addDocument($event, bidder, 'PASSPORT_FRONT')"
                            @fileRemoved="removeDocument($event, bidder, 'PASSPORT_FRONT')"></files-upload>
                        <files-upload :name="'clients[' + i + '][documents]'" doc-type="PASSPORT_BACK"
                            :file-ids="getDocumentIds(bidder, 'PASSPORT_BACK')"
                            :inner-label="fileCategories['PASSPORT_BACK']"
                            @fileSelected="addDocument($event, bidder, 'PASSPORT_BACK')"
                            @fileRemoved="removeDocument($event, bidder, 'PASSPORT_BACK')"></files-upload>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <files-upload label="Recibos" :name="'clients[' + i + '][documents]'" doc-type="PAY_SLIP"
                            :file-ids="getDocumentIds(bidder, 'PAY_SLIP')" :inner-label="fileCategories['PAY_SLIP']"
                            icon="pay_slip" @fileSelected="addDocument($event, bidder, 'PAY_SLIP')"
                            @fileRemoved="removeDocument($event, bidder, 'PAY_SLIP')"></files-upload>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <files-upload label="IRS" :name="'clients[' + i + '][documents]'" doc-type="IRS_DECLARATION"
                            :file-ids="getDocumentIds(bidder, 'IRS_DECLARATION')"
                            :inner-label="fileCategories['IRS_DECLARATION']" icon="irs_statement"
                            @fileSelected="addDocument($event, bidder, 'IRS_DECLARATION')"
                            @fileRemoved="removeDocument($event, bidder, 'IRS_DECLARATION')"></files-upload>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <files-upload label="Extratos" :name="'clients[' + i + '][documents]'" doc-type="BANK_STATEMENT"
                            :file-ids="getDocumentIds(bidder, 'BANK_STATEMENT')"
                            :inner-label="fileCategories['BANK_STATEMENT']" icon="bank_statement"
                            @fileSelected="addDocument($event, bidder, 'BANK_STATEMENT')"
                            @fileRemoved="removeDocument($event, bidder, 'BANK_STATEMENT')"></files-upload>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <files-upload label="Liquidação IRS" :name="'clients[' + i + '][documents]'"
                            doc-type="LIQUIDATION_NODE" :file-ids="getDocumentIds(bidder, 'LIQUIDATION_NODE')"
                            :inner-label="fileCategories['LIQUIDATION_NODE']" icon="settlement_note"
                            @fileSelected="addDocument($event, bidder, 'LIQUIDATION_NODE')"
                            @fileRemoved="removeDocument($event, bidder, 'LIQUIDATION_NODE')"></files-upload>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group w-100" role="group">
            <button class="btn btn-secondary" type="button" @click="addBidder">
                <svg-vue icon="plus" /> Cliente Adicional
            </button>
            <button class="btn btn-dark" type="button" @click="addBidder">
                <svg-vue icon="plus" /> Fiador
            </button>
        </div>
    </div>

</template>

<script>
export default {
    mounted() {
        this.bidders = [...this.clients];
        this.professionalItems = this.professionalOptions;
        this.bondItems = this.bondOptions;
        this.expenseItems = this.expenseOptions;

        if (this.bidders.length === 0) {
            this.addBidder()
        }

    },
    props: {
        clients: {
            type: Array,
            required: true,
        },
        professionalOptions: {
            type: Array,
            required: true,
        },
        bondOptions: {
            type: Array,
            required: true,
        },
        expenseOptions: {
            type: Array,
            required: true,
        },
        fileCategories: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            config: { ...window.config },
            bidders: [],
            professionalItems: [],
            bondItems: [],
        }
    },

    methods: {
        isBondVisible(professionalSituation) {
            return professionalSituation !== 'WORKING';
        },
        addBidder() {
            this.bidders.push({
                id: null,
                client_name: null,
                client_nif: null,
                client_birth_date: null,
                client_profession: null,
                client_professional_situation: 'WORKING',
                client_bond: 'unlimited',
                client_net_salary: null,
                client_capital: null,
                expenses: [],
                documents: [],
            });
        },
        addExpenses(index) {
            this.bidders[index].expenses.push({
                value: 0,
                expiration_date: '2030-01-01',
                type: 'car',
            });
        },
        deleteBidder(i) {
            this.bidders.splice(i, 1);
        },
        deleteExpenses(i, j) {
            this.bidders[i].expenses.splice(j, 1);
        },
        getDocumentIds(bidder, type) {
            if (!bidder?.documents) {
                return []
            };

            return bidder.documents.filter(d => d.type === type).map(d => d.file_id);
        },
        addDocument(file, bidder, type) {
            bidder.documents.push({
                file_id: file.id,
                type: type,
            });
        },
        removeDocument(fileId, bidder, type) {
            const index = bidder.documents.findIndex(d => d.file_id === fileId && d.type === type);
            if (index === -1) {
                return false;
            }
            bidder.documents.splice(index, 1);
        }

    }
}
</script>
