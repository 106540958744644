<template>
    <section class="kpis">
        <div class="kpi-section">
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'NEW')">
                    <p>Novos</p>
                    <h5>{{ getValue('NEW') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'IN_PROGRESS')">
                    <p>Produção</p>
                    <h5>{{ getValue('IN_PROGRESS') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'REVIEW')">
                    <p>Aprovação</p>
                    <h5>{{ getValue('REVIEW') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'APPROVED')">
                    <p>Concluídos</p>
                    <h5>{{ getValue('APPROVED') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event)">
                    <p>Total</p>
                    <h5>{{ getTotal() }}</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const selectedClass = 'kpi-header-selected';

export default {
    mounted() {

    },
    props: {
        stats: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        getValue(type) {
            return this.stats[type] || 0;
        },
        getTotal(type) {
            let total = 0;

            for (let s in this.stats) {
                total += Number.parseInt(this.stats[s]);
            }

            return total;
        },
        sendTableEvent(event, filter1) {
            let target = $(event.target);
            if (!target.hasClass('kpi') && !target.hasClass('kpi-header')) {
                target = target.parent();
            }

            if (target.hasClass(selectedClass)) {
                filter1 = null;
            } else {
                $('.' + selectedClass).each((i, e) => {
                    $(e).removeClass(selectedClass);
                })
            }

            target.toggleClass(selectedClass);

            const filters = [];

            if (filter1) {
                filters.push({
                    'field': 'state',
                    'value': filter1,
                })
            }
            window.bus.$emit('filter-table', filters);
        }
    }
}
</script>

