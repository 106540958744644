

<template>
    <div class="form-floating mb-3">
        <textarea v-bind="$attrs" class="form-control" :placeholder="placeholder || label" :name="name"
            :value="value" v-mask="mask" @change="onChange($event)"></textarea>
        <label>{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
        },
        value: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {
            type: String,
        },
        mask: {
            type: Object,
            default: () => { },
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        }
    }

}
</script>

