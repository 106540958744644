<template>
    <div>
        <div class="kpi-block">
            <div class="kpi-header">
                <h5>{{ title }}</h5>
                <span class="kpi-indicator" :class="isDifferenceNegative">{{ rangesRatio }}</span>
            </div>
            <div class="kpi-dashboard">
                <div class="kpi">
                    <span class="kpi-total">{{ totalRange2 }} <span class="kpi-indicator"
                            :class="isDifferenceNegative">{{ totalDifference }}</span></span>
                    <small>Últimos 60 dias</small>
                </div>
                <div class="kpi previous">
                    <span class="kpi-total">{{ totalRange1 }}</span>
                    <small>Periodo Anterior</small>
                </div>
            </div>
        </div>
        <!-- <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
            :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
            :height="height" /> -->
    </div>
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'


export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    mounted() {
        if (!this.data) {
            return;
        }

        const { title, data } = this.data;
        this.title = title;
        this.chartData.labels = data[1].map(k => k.date);
        this.chartData.datasets[0].data = data[0].map(k => k.amount);
        this.chartData.datasets[1].data = data[1].map(k => k.amount);
        // console.log(this.chartData.labels);
        // console.log(this.chartData.datasets[0].data);
        // console.log(this.chartData.datasets[1].data);
    },
    props: {
        data: {
            type: Object,
        },
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 250
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            title: '',
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July'
                ],
                datasets: [
                    {
                        label: 'Periodo Anterior',
                        backgroundColor: 'rgba(55,55,55,0.1)',
                        borderWidth: 0,
                        data: [40, 39, 10, 40, 39, 80, 40],
                        fill: true,
                    },
                    {
                        label: 'Últimos 60 dias',
                        backgroundColor: 'rgba(18,50,86,1)',
                        borderColor: 'rgba(18,50,86,0.8)',
                        borderWidth: 2,
                        data: [20, 139, 100, 140, 139, 180, 140],
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.4  // smooth lines
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    y: {
                        display: false,
                        beginAtZero: true,
                    },
                    x: {
                        ticks: {
                            display: false
                        }
                    }
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    subtitle: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    }
                },
                radius: 0,
                layout: {
                    padding: 5
                }

            }
        }
    },
    computed: {
        // a computed getter
        rangesRatio() {
            return Math.round((this.totalRange2 - this.totalRange1) / this.totalRange2 * 100) + '%';
        },
        totalRange1() {
            const totalRange1 = this.chartData.datasets[0].data.reduce((p, c) => p + c, 0);
            return totalRange1;
        },
        totalRange2() {
            const totalRange2 = this.chartData.datasets[1].data.reduce((p, c) => p + c, 0);
            return totalRange2;
        },
        totalDifference() {
            const totalDifference = this.totalRange2 - this.totalRange1
            return totalDifference;
        },
        isDifferenceNegative() {
            return {
                'kpi-indicator-negative': (this.totalDifference < 0)
            }
        }
    }
}
</script>
  