<template>
    <div>
        <input type="hidden" name="id" :value="model" />
        <div class="row">
            <div class="col-12">
                <card-component title="Nome da Acção">
                    <drop-down-component label="Property" v-model="selectedProperty" :items="getModelProperties()"
                        allow-empty @onChange="addProperty($event)" />
                </card-component>
            </div>
            <div class="col-12 col-sm-6 col-md-4" v-for="row in properties">
                <card-component :title="row.property">
                    <div class="row">
                        <div class="col-10">
                            <drop-down-component label="Add Role" :items="getAvailableRowRoles(row.roles)" allow-empty
                                @onChange="addRole($event, row)" />
                        </div>
                        <div class="col-2">
                            <button-component @click="removeProperty(row.property)" class="btn-danger" icon="delete" />
                        </div>
                    </div>
                    <div class="row pb-2" v-for="role in row.roles">
                        <div class="col-10">
                            <input type="hidden" :name="'roles[' + row.property + '][]'" :value="role.id" />
                            {{ role.name }}
                        </div>
                        <div class="col-2">
                            <button-component @click="removeRole(role.id, row)" class="btn-danger" icon="delete" />
                        </div>
                    </div>
                </card-component>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        if (this.detailData.length) {
            this.detailData.forEach(d => {
                let r = this.properties.find(p => p.property === d.field);
                if (!r) {
                    this.addProperty(d.field)
                    r = this.properties.find(p => p.property === d.field);
                }
                const role = this.getRoleById(d.role_id);
                if (role) {
                    r.roles.push(role)
                }
            })
        }
    },
    props: {
        model: String,
        modelData: Array,
        detailData: Array,
        roles: Array,
    },
    data() {
        return {
            selectedProperty: null,
            properties: [],
        }
    },
    methods: {
        getModelProperties() {
            const modelProperties = this.modelData.find(i => i.model === this.model)?.properties || [];
            return modelProperties.filter(p => !this.properties.find(i => i.property === p)).map(p => { return { id: p, name: p } });
        },
        addProperty(property, roles = []) {
            this.properties.push({
                property,
                roles
            });
            this.selectedProperty = null;
        },
        removeProperty(property) {
            this.properties.splice(this.properties.findIndex(p => p.property === property), 1);
        },
        addRole(roleId, row) {
            const role = this.getRoleById(roleId);
            if (role) {
                row.roles.push(role);
            }
        },
        removeRole(roleId, row) {
            row.roles = row.roles.filter(r => r.id !== roleId)
        },
        getRoleById(roleId) {
            const role = this.roles.find(r => r.id === roleId);
            if (!role) {
                return null;
            }
            return { id: role.id, name: role.title };
        },
        getAvailableRowRoles(roles) {
            return this.roles.filter(r => !roles.find(i => i.id === r.id)).map(r => ({ id: r.id, name: r.title }));
        }
    }
}
</script>
