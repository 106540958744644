<template>
    <div class="doc-upload">
        <div class="doc-upload-header">
            <svg-vue v-if=icon :icon="icon" /> {{ label }}
        </div>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div v-if="preview" v-for="fileId in files">
            <file-upload-component @fileRemoved="removeFile" :name="getFileNameForInput(fileId)" :value="fileId" />
            <input v-if="docType" type="hidden" :name="getTypeNameForInput(fileId)" :value="docType" />
        </div>
        <div v-if="!preview">
            <span type="button" class="doc-upload-modal" v-if="files.length" @click="showModal" title="Ver Documentos Carregados"><svg-vue icon="view" /></span>

            <modal-component v-if="modalOpen" @close="hideModal">
                <h3 slot="header">Documentos {{ label }}</h3>
                <div slot="body" >
                    <div v-if="files.length" v-for="fileId in files">
                        <file-upload-component @fileRemoved="removeFile" :name="getFileNameForInput(fileId)" :value="fileId" />
                        <input v-if="docType" type="hidden" :name="getTypeNameForInput(fileId)" :value="docType" />
                    </div>
                    <div v-if="!files.length">
                        Nenhum documento carregado
                    </div>
                </div>
            </modal-component>


        </div>

        <file-upload-component @fileSelected="addFile" :value="null" :inner-label="innerLabel" :as-proxy="true" />
    </div>
</template>

<script>
export default {
    mounted() {
        this.files = this.fileIds;
    },
    props: {
        fileIds: {
            type: Array,
            default: () => [],
        },
        docType: {
            type: String,
        },
        label: String,
        innerLabel: String,
        name: String,
        icon: {
            type: String,
            default: null
        },
        preview: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            files: [],
            loading: false,
            modalOpen: false,
        };
    },
    watch: {
        fileIds: function (newFiles, oldFiles) {
            this.files = newFiles;
        }
    },
    methods: {
        getFileNameForInput(fileId) {
            return `${this.name}[${fileId}][file_id]`;
        },
        getTypeNameForInput(fileId) {
            return `${this.name}[${fileId}][type]`;
        },
        addFile(file) {
            this.files.push(file.id);
            this.$emit('fileSelected', file);
        },
        removeFile(fileId) {
            let newFiles = this.files.filter(fId => fId !== fileId);
            this.files = newFiles;
            this.$emit('fileRemoved', fileId);
        },

        showModal() {
            this.modalOpen = true;
        },
        hideModal() {
            this.modalOpen = false;
        },
    },
}
</script>
