<template>
    <div class="doc-upload">
        <div class="doc-upload-header" v-if="label">
            <svg-vue v-if=icon :icon="icon" /> {{ label }}
        </div>
        <div v-if="!readonly" class="doc-upload-body">
            <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>

            <div v-if="!file" class="doc-upload-area" @click="selectFile">
                <svg-vue icon="upload" />
                <div class="doc-upload-area-body">
                    <h6 class="doc-upload-title">{{ innerLabel || label }}</h6>
                    <small>{{ getFileRestrictionText() }}</small>
                </div>
            </div>
            <div v-else>
                <file-preview-component :file="file"></file-preview-component>
                <div class="wow-file-actions">
                    <a :href="file.url" download title="Descarregar Documento"><svg-vue icon="download"/></a>
                    <span @click="removeFile" title="Eliminar Documento"><svg-vue icon="delete" /></span>
                </div>
            </div>
        </div>
        <div v-else class="doc-upload-body">
            <div v-if="!file" class="doc-upload-area">
                <svg-vue icon="upload" />
                <div class="doc-upload-area-body">
                    <h6 class="doc-upload-title"> - </h6>
                </div>
            </div>
            <div v-else>
                <file-preview-component :file="file"></file-preview-component>
            </div>
        </div>

        <input v-if="file" :name="name" type="text" :value="file.id" v-show="false" readonly>
        <input v-else :name="name" type="text" v-show="false" readonly>
        <input ref="fileInput" type="file" @input="pickFile" v-show="false">
    </div>
</template>

<script>
export default {
    mounted() {
        if (this.value) {
            this.loadFile();
        }
    },
    watch: {
        value: function (newValue, oldValue) {
            if (newValue && this.file?.id !== newValue) {
                this.loadFile();
            }
        }
    },
    props: {
        value: {
            default: null
        },
        label: String,
        innerLabel: String,
        name: String,
        icon: {
            type: String,
            default: null
        },
        preview: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },
        asProxy: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            file: null,
            loading: false,
        };
    },
    methods: {
        getFileRestrictionText() {
            return 'Máximo 8Mb (pdf, jpeg, jpg, png)';
        },
        selectFile() {
            this.$refs.fileInput.click()
        },
        loadFile() {
            this.loading = true;
            this.file = null;
            axios.post(window.searchUrl, {
                id: this.value,
                model: 'file'
            })
                .then(response => {
                    if (response.status === 200 && response.data.id) {
                        this.file = response.data;
                    }
                }).catch(e => {
                    this.file = null;
                }).finally(e => {
                    this.loading = false;
                })
        },
        removeFile() {
            if (this.file) {
                this.$emit('fileRemoved', this.file.id);
                this.$emit('input', null);
                this.$emit('onChange', null);
            }
            this.file = null;
        },
        pickFile() {
            let input = this.$refs.fileInput
            let file = input.files

            if (file && file[0]) {
                const fileToUpload = file[0];
                const formData = new FormData();
                formData.append('file', fileToUpload);

                this.loading = true;
                axios.post(window.moduleFileUrlStore, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (!this.asProxy) {
                        this.file = response.data;
                    }

                    this.$emit('fileSelected', response.data);
                    this.$emit('input', response.data.id);
                    this.$emit('onChange', response.data.id);
                    this.$refs.fileInput.value = '';
                }).catch(e => {
                    toastr.error(e.response.data.message);
                    this.file = null;
                }).finally(e => {
                    this.loading = false;
                })
            }
        }
    },
}
</script>
