<template>
    <div :class="'property-upload-section property-upload-section-' + showNotApplicable()">
        <div class="property-upload-header">
            <span><svg-vue v-if=icon :icon="icon" /><svg-vue v-if="description" icon="info" class="property-info-helper" v-b-tooltip.hover:title="description" /> {{ label }}</span>

            <div class="btn-group btn-group-sm" role="group">
                <input @change="optionChanged" type="radio" class="btn-check" :id="getOptionId(-1)"
                    :name="getName() + '[option]'" value="-1" v-model="option" v-if="showNotApplicable()" :disabled=disabled>
                <label class="btn btn-outline-secondary" :for="getOptionId(-1)"
                    v-if="showNotApplicable()">N/A</label>

                <input @change="optionChanged" type="radio" class="btn-check" :id="getOptionId(1)"
                    :name="getName() + '[option]'" value="1" v-model="option" :disabled=disabled>
                <label class="btn btn-outline-secondary" :for="getOptionId(1)">Sim</label>

                <input @change="optionChanged" type="radio" class="btn-check" :id="getOptionId(0)"
                    :name="getName() + '[option]'" value="0" v-model="option" :disabled=disabled>
                <label class="btn btn-outline-secondary" :for="getOptionId(0)">Não</label>
            </div>
        </div>

        <files-upload v-if="canUploadFiles()" :name="getName() + '[files]'" :doc-type="docType" :file-ids="fileIds"
            :inner-label="innerLabel" @fileSelected="addFile" @fileRemoved="removeFile" :preview="false"></files-upload>
        <input type="hidden" :name="getName() + '[s]'" :value="docType" />
    </div>
</template>

<script>
export default {
    mounted() {
        this.load(this.documents);
    },
    watch: {
        documents: function (newDocs, oldDocs) {
            this.load(newDocs);
        }
    },
    props: {
        propertyId: {
            type: String,
            default: null,
        },
        documents: {
            type: Object,
            default: () => { },
        },
        docType: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'label',
        },
        innerLabel: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            option: 0,
            fileIds: [],
            randomId: '',
            disabled: !this.propertyId,
            notApplicable: ['CMI', 'ONUS', 'PLANTA', 'ESTADO_CIVIL', 'CARTAO_CIDADAO', 'VERIFICACAO_ASSINATURA', 'PLANTA_LOCALIZACAO', 'AFETACAO', 'CADERNETA_PREDIAL_URBANA', 'MORADA', 'AREAS', 'CERTIDAO_REGISTO_PREDIAL'],
            uploadable: ['CMI', 'CARTAO_CIDADAO', 'PROCURACAO', 'CARTAO_CIDADAO_GERENTES', 'HABILITACAO_HERDEIROS', 'CERTIDAO_REGISTO_PREDIAL', 'CADERNETA_PREDIAL_URBANA', 'CERTIFICADO_ENERGETICO', 'LICENCA_UTILIZACAO', 'FICHA_TECNICA_HABITACAO', 'CERTIDAO_INFRASTUTURAS', 'PLANTA', 'PLANTA_LOCALIZACAO', 'PROCURACAO', 'IDENTIFICACAO_PROCURADORES', 'CERTIDAO_COMERCIAL', 'CODIGO_RCBE', 'ATA_ALIENACAO'],
            showModal: false
        };
    },
    methods: {
        load(doc) {
            this.randomId = 'random_id_' + Math.floor(Math.random() * 10000000);
            this.option = doc.option;
            this.fileIds = doc.files.map(key => key);
        },
        getName() {
            return `${this.name}[${this.docType}]`;
        },

        setOption(option) {
            this.option = option;
        },
        getOptionId(option) {
            return this.randomId + '_' + option;
        },
        addFile(file) {
            const data = {
                property_id: this.propertyId,
                option: this.option,
                type: this.docType,
                file_id: file?.id
            }
            axios.post(window.modulePropertyDocumentUrlStore, data)
                .then(response => {
                    toastr.success("Documento gravado com sucesso.");
                }).catch(e => {
                    toastr.error("Erro ao gravar Documento.");
                }).finally(e => {
                })

        },
        removeFile(fileId) {
            const data = {
                property_id: this.propertyId,
                type: this.docType,
                file_id: fileId
            }
            axios.post(window.modulePropertyDocumentUrlDelete, data)
                .then(response => {
                    toastr.success("Documento removido com sucesso.");
                }).catch(e => {
                    toastr.error("Erro ao remover Documento.");
                }).finally(e => {
                })

        },
        optionChanged() {
            this.addFile(null);
        },
        canUploadFiles() {
            if (!this.uploadable.includes(this.docType)) {
                return false;
            }

            // use lose type comparation
            return this.option == 1;
        },
        showNotApplicable() {
            return (!this.notApplicable.includes(this.docType));
        },
    },
}
</script>
