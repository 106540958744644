<template>
    <button type="button" class="btn btn-success" @click="onClick($event)">
        {{ label }}
        <svg-vue :icon="icon" />
    </button>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },
        icon: {
            type: String,
            default: 'delete'
        },
    },
    methods: {
        onClick(event) {
            event.preventDefault();
            this.$emit('click', event.target.value);
        },
    }
}
</script>

<!-- <button type="button" class="btn btn-primary">Primary</button>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-success">Success</button>
<button type="button" class="btn btn-danger">Danger</button>
<button type="button" class="btn btn-warning">Warning</button>
<button type="button" class="btn btn-info">Info</button>
<button type="button" class="btn btn-light">Light</button>
<button type="button" class="btn btn-dark">Dark</button> -->
