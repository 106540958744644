<template>
    <section class="kpis">
        <div class="kpi-section">
            <div class="kpi-header">
                <div class="kpi-title">Processos Ativos</div>
                <div class="kpi-header-total">
                    <div>
                        <small>c/Imóvel</small>
                        {{ getValue('TOTAL_ACTIVE', 'withProperty') }}
                    </div>
                    <div>
                        <small>s/Imóvel</small>
                        {{ getValue('TOTAL_ACTIVE', 'withOutProperty') }}
                    </div>
                    <div>
                        <small>Total</small>
                        {{ getValue('TOTAL_ACTIVE', 'total') }}
                    </div>
                </div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'NEW_CLIENT')">
                    <p>Novos</p>
                    <h5>{{ getValue('NEW', 'total') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'QUALIFICATION')">
                    <p>Qualifição</p>
                    <h5>{{ getValue('QUALIFICATION', 'total') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'DECISION')">
                    <p>Decisão</p>
                    <h5>{{ getValue('DECISION', 'total') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'CLOSED')">
                    <p>Escriturados</p>
                    <h5>{{ getValue('CLOSED', 'total') }}</h5>
                </div>
            </div>
        </div>
        <div class="kpi-section inactive">
            <div class="kpi-header">
                <div class="kpi-title">Processos Inativos</div>
                <div class="kpi-header-total">
                    <div>
                        <small>c/Imóvel</small>
                        {{ getValue('TOTAL_INACTIVE', 'withProperty') }}
                    </div>
                    <div>
                        <small>s/Imóvel</small>
                        {{ getValue('TOTAL_INACTIVE', 'withOutProperty') }}
                    </div>
                    <div>
                        <small>Total</small>
                        {{ getValue('TOTAL_INACTIVE', 'total') }}
                    </div>
                </div>
            </div>
            <div class="kpi-body">
                <div class="kpi" @click="sendTableEvent($event, 'A_PENDING')">
                    <p>Arquivados</p>
                    <h5>{{ getValue('ARCHIVED', 'total') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'P_PROPERTY')">
                    <p>Pendentes Imóvel</p>
                    <h5>{{ getValue('PENDING_PROPERTY', 'total') }}</h5>
                </div>
                <div class="kpi" @click="sendTableEvent($event, 'P_C_ANSWER')">
                    <p>Pendentes Cliente</p>
                    <h5>{{ getValue('PENDING_CLIENT', 'total') }}</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const selectedClass = 'kpi-header-selected';

export default {
    mounted() {

    },
    props: {
        stats: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        getValue(state, type) {
            try {
                return this.stats[state][type];
            }
            catch (e) {

            }
        },
        getTotal(type) {
            let total = 0;

            for (let s in this.stats) {
                total += Number.parseInt(this.stats[s][type]);
            }

            return total;
        },
        sendTableEvent(event, filter1) {
            let target = $(event.target);
            if (!target.hasClass('kpi') && !target.hasClass('kpi-header')) {
                target = target.parent();
            }

            if (target.hasClass(selectedClass)) {
                filter1 = null;
            } else {
                $('.' + selectedClass).each((i, e) => {
                    $(e).removeClass(selectedClass);
                })
            }

            target.toggleClass(selectedClass);

            const filters = [];

            if (filter1) {
                filters.push({
                    'field': 'status',
                    'value': filter1,
                })
            }
            window.bus.$emit('filter-table', filters);
        }
    }
}
</script>

