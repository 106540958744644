<template>
    <card-component title="Inventário">
        <div class="row">
            {{ (counter = 0, null) }}
            <div v-for="(cType, i) in characteristicTypes" :key="cType.id" class="col-md-6">
                <div>
                    <option-component :name="getOptionName(cType.id)" :label="cType.name"
                        :options="getOptionsForType(cType.id)" v-model="value[getOptionName(cType.id)]">
                    </option-component>

                    <div v-if="isVisible(cType.id)" v-for="(characteristic, j) in gch = getCharacteristics(cType.id)" :key="cType.id + '_' + j" >
                        <div class="row">
                            <div class="col-12 pt-2">
                                <floating-label-component :name="'characteristics[' + counter + '][observation]'"
                                    label="Observações" v-model="characteristic.observation" />
                            </div>
                        </div>

                        {{ (counter++, null) }}
                    </div>
                </div>
            </div>
            <!-- <card-component title="Inventário">
        {{ (counter = 0, null) }}
        <div v-for="(cType, i) in characteristicTypes" :key="cType.id">
            <div class="row">
                <div class="col">
                    <option-component :name="getOptionName(cType.id)" :label="cType.name"
                        :options="getOptionsForType(cType.id)" v-model="value[getOptionName(cType.id)]">
                        <button v-if="isVisible(cType.id)" class="btn btn-secondary" type="button"
                            @click="addCharacteristic(cType.id)">
                            <svg-vue icon="plus" />
                        </button>
                    </option-component>
                </div>
            </div>

            <div v-if="isVisible(cType.id)" v-for="(characteristic, j) in gch = getCharacteristics(cType.id)"
                :key="cType.id + '_' + j">
                <input type="text" :name="'characteristics[' + counter + '][id]'" :value="characteristic.id" hidden />
                <input type="text" :name="'characteristics[' + counter + '][type]'" :value="cType.id" hidden />
                <div class="d-flex justify-content-between">
                    <label class="card-subtitle">{{ cType.name }} <span v-html="j + 1"></span></label>
                    <button v-if="getCharacteristics(cType.id).length > 1" class="btn btn-link" type="button"
                        @click="deleteCharacteristic(cType.id, j)">
                        <svg-vue icon="delete" />
                    </button>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <floating-label-component :name="'characteristics[' + counter + '][name]'" label="Nome"
                            v-model="characteristic.name" />
                    </div>
                    <div class="col-md-3">
                        <floating-label-component :name="'characteristics[' + counter + '][value]'" label="Valor"
                            v-model="characteristic.value" />
                    </div>
                    <div class="col-md-3">
                        <floating-label-component :name="'characteristics[' + counter + '][observation]'"
                            label="Observações" v-model="characteristic.observation" />
                    </div>
                </div>

                {{ (counter++, null) }}
            </div>
        </div> -->
        </div>
    </card-component>
</template>

<script>
export default {
    mounted() {
        this.characteristics = this.value?.characteristics || [];
        this.characteristicTypes.forEach(c => {
            const c2 = this.characteristics.filter(c3 => c3.type === c.id)
            if (!c2.length) {
                this.addCharacteristic(c.id);
            }
        });

    },
    props: {
        value: { type: Object, required: true },
        characteristicTypes: Array,
        equipmentTypes: Array,
        furnitureTypes: Array,
    },
    data() {
        return {
            characteristics: [],
        }
    },
    methods: {
        getOptionName(type) {
            return 'characteristics_' + type.toLowerCase()
        },
        getOptionsForType(type) {
            return this[type.toLowerCase() + 'Types'];
        },
        isVisible(type) {
            const c = this.value[this.getOptionName(type)];
            if (!c) {
                return false;
            }

            return !c.startsWith('NOT');
        },
        getCharacteristics(type) {
            return this.characteristics.filter(c => c.type === type)
        },
        addCharacteristic(type) {
            this.characteristics.push({
                id: null,
                name: null,
                value: null,
                observation: null,
                type,
            });
        },
        deleteCharacteristic(type, i) {
            let counter = 0;
            const index = this.characteristics.findIndex(ch => {
                if (ch.type === type && counter === i) {
                    return true;
                }

                if (ch.type === type) {
                    counter++
                }

                return false;
            });

            this.characteristics.splice(index, 1);
        },


    }
}
</script>

