<template>
    <section>
        <div class="comments">
            <div v-if="loading" class="d-flex align-items-center p-1 h-100">
                <strong>A carregar comentários...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            <div v-else v-bind:class="getClass(comment)" v-for="comment, i in comments" v-bind:key="comment.id">
                <div class="preview">
                    <div v-for="file in comment.files" v-if="comment.files && comment.files.length" :key="file.id" class="preview-item">
                        <a :href="file.url" target="_blank" title="Visualizar Documento">
                            <img v-if="file.mime_type && file.mime_type.startsWith('image/')" :src="file.url" />
                            <span v-else>{{ file.name }}</span>
                        </a>
                        <a :href="file.url" download title="Descarregar Documento" class="action"><svg-vue icon="download"/></a>
                    </div>
                </div>
                <div class="user">{{ comment.user.name }}</div>
                <div class="comment" v-html="nl2br(comment.comment)"></div>
                <div>
                    <span class="datetime">{{ formatDate(comment.created_at) }}</span>
                    <div class="is-read">
                        <div v-if="comment.read">
                            <svg-vue icon="check_full" color="#060" />
                        </div>
                        <div v-else @click="markRead(i)">
                            <svg-vue icon="check_full" color="#600" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="new-comment">
            <div class="preview">
                <div v-for="(preview, index) in previews" :key="index" class="preview-item">
                    <img v-if="preview.type && preview.type.startsWith('image/')" :src="preview.data" />
                    <p v-else>{{ preview.name }}</p>
                    <span title="Remover Documento" class="action" @click="removeFile(index)"><svg-vue icon="delete"/></span>
                </div>
            </div>
            <div class="new-comment-block">
                <div class="new-comment-input">
                    <!-- <input type="text" v-model="message" class="form-control" placeholder="Escreva uma mensagem"> -->
                    <textarea v-model="message" class="form-control" placeholder="Escreva uma mensagem/comentário (agora permite multilinhas)"></textarea>
                    <div class="actions">
                        <span @click="openFileInput" title="Anexar ficheiro"><svg-vue icon="attachment" color="#0F2236"/></span>
                    </div>
                </div>
                <button class="btn btn-primary" @click="addComment" v-if="message">
                    <svg-vue icon="send" color="#fff"/>
                </button>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept=".jpeg, .jpg, .png, .doc, .docx, .pdf" multiple />
            </div>
        </footer>
    </section>
</template>

<script>

export default {
    mounted() {
        this.loadComments();
    },
    props: {
        model: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            comments: [],
            message: '',
            files: [],
            previews: [],
        };
    },
    methods: {
        getClass(item) {
            if (item.user_id === window.currentUser.id) {
                return 'comment-block my-comment';
            }

            return 'comment-block';
        },
        addComment() {
            this.loading = true;
            let formData = new FormData();
            formData.append('model', this.model);
            formData.append('model_id', this.id);
            formData.append('comment', this.message);
            if (this.files.length > 0) {
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[]', this.files[i]);
                }
            }
            this.message = '';
            this.files = [];
            this.previews = [];
            axios.post(window.moduleCommentUrlStore, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                this.comments.unshift(response.data);
                for (let j = 0; j < this.comments.length; j++) {
                    this.comments[j].read = true;
                }
            }).catch(e => {
            }).finally(e => {
                this.loading = false;
            })
        },
        openFileInput() {
            this.$refs.file.click();
        },
        handleFileUpload(){
            const newFiles = Array.from(this.$refs.file.files);
            const newPreviews = newFiles.map(file => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve({ data: e.target.result, type: file.type, name: file.name });
                    reader.onerror = (e) => reject(e);
                    reader.readAsDataURL(file);
                });
            });
            Promise.all(newPreviews).then(values => {
                this.files = [...this.files, ...newFiles];
                this.previews = [...this.previews, ...values];
            });
        },
        loadComments() {
            this.loading = true;
            const data = {
                model: this.model,
                id: this.id,
            };
            axios.get(window.moduleCommentUrlLoad, { params: data })
                .then(response => {
                    this.comments = response.data;
                }).catch(e => {
                    this.comments = [];
                }).finally(e => {
                    this.loading = false;
                })
        },
        formatDate(date) {
            return moment.duration(moment(date).diff(moment(new Date()))).humanize(true);
        },
        markRead(i) {
            this.loading = true;
            const data = {
                commentId: this.comments[i].id,
            };
            axios.post(window.moduleCommentReadUrlStore, data)
                .then(response => {
                    for (let j = i; j < this.comments.length; j++) {
                        this.comments[j].read = true;
                    }
                }).catch(e => {

                }).finally(e => {
                    this.loading = false;
                })
        },
        removeFile(index) {
            this.files.splice(index, 1);
            this.previews.splice(index, 1);
        },
        nl2br(str) {
        return str.replace(/\n/g, '<br>');
        }
    }
}
</script>
