<template>
    <div>
        <a :href="file.url" target="_blank" title="Visualizar Documento">
            <img :src="file.url" style="max-height: 250px;" class="img-fluid" v-if="file.mime_type.startsWith('image/')">
            <span v-if="file.mime_type.startsWith('application/')">
                {{ file.name }}
            </span>
        </a>
    </div>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        file: Object,
    },
}
</script>
