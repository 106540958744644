<template>
    <div>
        <div v-for="(client, i) in clients" :key="i">
            <input type="text" :name="'clients[' + i + '][id]'" v-model="client.id" hidden />
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <label class="card-subtitle">Cliente <span v-html="i + 1"></span></label>
                        <button v-if="clients.length > 1" class="btn btn-link" type="button" @click="deleteClient(i)">
                            <svg-vue icon="delete" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <floating-label-component label="Nome do Cliente" :name="'clients[' + i + '][name]'"
                        v-model="client.name" />
                </div>
                <div class="col-md-2">
                    <floating-label-percentage-component label="Quota Parte" :name="'clients[' + i + '][share]'"
                        v-model="client.share" />
                </div>
                <div class="col-md-3">
                    <floating-label-component label="NIF" :name="'clients[' + i + '][nif]'" v-model="client.nif" />
                </div>
                <div class="col-md-3">
                    <floating-label-component label="Email" :name="'clients[' + i + '][email]'" v-model="client.email" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <floating-label-component label="Morada do Cliente" :name="'clients[' + i + '][address]'"
                        v-model="client.address" />
                </div>
                <div class="col-md-3">
                    <floating-label-component label="Código Postal" :name="'clients[' + i + '][zipcode]'"
                        v-model="client.zipcode" />
                </div>
                <div class="col-md-3">
                    <floating-label-component label="Localidade" :name="'clients[' + i + '][city]'" v-model="client.city" />
                </div>
            </div>
        </div>
        <div class="btn-group w-100" role="group">
            <button class="btn btn-secondary" type="button" @click="addClient">
                <svg-vue icon="plus" /> Cliente Adicional
            </button>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.clients = this.invoiceClients;

        if (this.clients.length === 0) {
            this.addClient()
        }
    },
    props: {
        invoiceClients: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            clients: [],
        }
    },

    methods: {
        addClient() {
            this.clients.push({
                id: null,
                name: null,
                nif: null,
                email: null,
                address: null,
                zipcode: null,
                city: null,
            });
        },
        deleteClient(i) {
            this.clients.splice(i, 1);
        },
    }
}
</script>
