<template>
    <div class="form-floating mb-3">
        <select :name="name" class="form-select" v-bind="$attrs" @change="onChange($event)">
            <option :value="item.id" :key="item.id" v-for="item in getItems()" :selected="isSelected(item)">{{
                    item.name
            }}</option>
        </select>
        <label>{{ placeholder || label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        placeholder: String,
        label: String,
        items: Array,
        value: String,
        allowEmpty: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        isSelected(item) {
            return item.id == this.value;
        },
        getItems() {
            const items = [];
            if (this.allowEmpty) {
                items.push({
                    id: null,
                    name: null,
                });
            }
            return [
                ...items,
                ...this.items,
            ];
        },
        onChange(event) {
            this.$emit('input', event.target.value);
            this.$emit('onChange', event.target.value);
        }
    }
}
</script>

