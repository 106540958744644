<template>
    <div>
        <input type="hidden" name="id" :value="eventNotification.id" />
        <div class="row">
            <div class="col-md-8">
                <card-component title="Nome da Acção">
                    <div class="row">
                        <div class="col-md-6">
                            <floating-label-component name="name" label="Nome" v-model="eventNotification.name" />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="model" label="Modelo" v-model="eventNotification.model" :items="models"
                                @onChange="resetFilter()" />
                        </div>
                        <div class="col-md-3">
                            <drop-down-component name="action" label="Acção" v-model="eventNotification.action"
                                :items="actionOptions" />
                        </div>
                    </div>
                </card-component>
                <div v-if="eventNotification.id">
                    <card-component title="Filtro" v-if="modelFilterOptions.length || modelFilter.length">
                        <div v-if="modelFilterOptions.length">
                            <div class="row">
                                <div class="col-md-3">
                                    <drop-down-component label="Filtro" v-model="selectedFilterOptionId"
                                        :items="[{ id: null, name: '' }, ...modelFilterOptions]" />
                                </div>
                                <div class="col-md-9" v-if="selectedFilterOptionId">
                                    <div class="row">
                                        <div class="col-4">
                                            <drop-down-component label="Antes" v-model="selectedFilterOptionIdBefore"
                                                :items="getSelectedFilterOptions()" allow-empty />
                                        </div>
                                        <div class="col-4">
                                            <drop-down-component
                                                v-if="selectedFilterOptionIdBefore !== 'HAS_VALUE' && selectedFilterOptionIdBefore !== 'HAS_ROLE' && selectedFilterOptionIdBefore !== 'HAS_NOT_ROLE'"
                                                label="Depois" v-model="selectedFilterOptionIdAfter"
                                                :items="getSelectedFilterOptions()" allow-empty />
                                            <floating-label-component v-if="selectedFilterOptionIdBefore === 'HAS_VALUE'"
                                                label="Valor" v-model="selectedFilterOptionIdAfter" />
                                            <drop-down-component v-if="selectedFilterOptionIdBefore === 'HAS_ROLE'" label="Role"
                                                v-model="selectedFilterOptionIdAfter" :items="getAvailableFilterRoles()" />
                                            <drop-down-component v-if="selectedFilterOptionIdBefore === 'HAS_NOT_ROLE'" label="Role"
                                                v-model="selectedFilterOptionIdAfter" :items="getAvailableFilterRoles()" />
                                        </div>
                                        <div class="col-4">
                                            <button-component v-if="selectedFilterOptionIdBefore || selectedFilterOptionIdAfter"
                                                @click="addFilter()" label="Adicionar" icon="plus" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="modelFilter.length">
                            <div class="row">
                                <div class="col-md-3">Valor</div>
                                <div class="col-md-3">Antes</div>
                                <div class="col-md-3">Depois</div>
                                <div class="col-md-3"></div>
                            </div>
                            <div class="row" v-for="(e, count) in modelFilter">
                                <div class="col-md-3">{{ e.value }}</div>
                                <div class="col-md-3">{{ e.before }}</div>
                                <div class="col-md-3">{{ renderAfter(e) }}</div>
                                <div class="col-md-3">
                                    <button-component @click="removeDataFilter(count)" icon="delete" class="btn-danger btn-sm" />
                                </div>
                            </div>
                        </div>
                    </card-component>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="eventNotification.id">
                    <card-component title="Relações" v-if="eventNotification.id && getRelations().length">
                        <div class="row" v-for="relation in getRelations()">
                            <div class="col-12">
                                {{ relation }}
                            </div>
                        </div>
                    </card-component>
                </div>
            </div>
        </div>

        <!-- Only Edit -->
        <div v-if="eventNotification.id">

            <card-component title="Notificação" v-if="eventNotification.id">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <floating-label-component name="origin" label="Enviar De" v-model="eventNotification.origin" />
                            </div>
                            <div class="col-md-6">
                                <floating-label-component name="title" label="Assunto" v-model="eventNotification.title" />
                            </div>
                            <div class="col-12">
                                <floating-label-text-area-component name="message" label="Mensagem"
                                    v-model="eventNotification.message" rows="5" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex gap-2">
                            <div class="flex-grow-1">
                                <drop-down-component label="Adicionar Cargo" v-model="selectedNotificationRoleId"
                                    :items="getAvailableNotificationRoles()" allow-empty />
                            </div>
                            <button-component v-if="selectedNotificationRoleId" @click="addSendToRole()" label="Adicionar" class="mb-3"
                                icon="plus" />
                        </div>
                        <div v-if="notificationData.roles.length">
                            <div class="row">
                                <div class="col-12">Cargos</div>
                            </div>
                            <div class="d-flex gap-2" v-for="(role, count) in notificationData.roles">
                                <div class="flex-grow-1">{{ getRole(role)?.name }}</div>
                                <button-component @click="removeSendToRole(count)" icon="delete" class="btn-danger" />
                            </div>
                        </div>

                        <div class="d-flex gap-2">
                            <div class="flex-grow-1">
                                <drop-down-component label="Adicionar Utilizador" v-model="selectedNotificationField" :items="modelFilterOptions"
                                    allow-empty />
                            </div>
                            <button-component v-if="selectedNotificationField" @click="addSendToUser()" label="Adicionar"
                                icon="plus" />
                        </div>
                        <div v-if="notificationData.users.length">
                            <div class="row">
                                <div class="col-12">Utilizadores</div>
                            </div>
                            <div class="d-flex gap-2" v-for="(field, count) in notificationData.users">
                                <div class="flex-grow-1">{{ field }}</div>
                                <button-component @click="removeSendToUser(count)" icon="delete" class="btn-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </card-component>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        if (this.eventNotification.model_filter?.length) {
            this.modelFilter = [...this.eventNotification.model_filter];
        }

        this.notificationData.roles = [...this.eventNotification.to?.roles || []];
        this.notificationData.users = [...this.eventNotification.to?.users || []];

        if (this.eventNotification.model) {
            this.loadFilterOptions();
        }
    },

    props: {
        eventNotification: Object,
        models: Array,
        actionOptions: Array,
    },
    data() {
        return {
            modelFilter: [],
            modelFilterOptions: [],
            selectedFilterOptionId: null,
            selectedFilterOptionIdBefore: null,
            selectedFilterOptionIdAfter: null,
            notificationData: {
                roles: [],
                users: [],
            },
            selectedNotificationRoleId: null,
            selectedNotificationField: null,
        }
    },
    methods: {
        resetFilter() {
            this.selectedFilterOptionId = null;
            this.filterOptions = [];
            this.resetFilterOptions();
            this.loadFilterOptions();
        },
        resetFilterOptions() {
            this.selectedFilterOptionIdBefore = null;
            this.selectedFilterOptionIdAfter = null;
        },
        getSelectedFilterOptions() {
            return this.modelFilterOptions.find(e => e.id === this.selectedFilterOptionId)?.options || [];
        },
        getAvailableFilterRoles() {
            return this.getRoles().filter(r => !this.modelFilter.find(f => (f.before === 'HAS_ROLE' || f.before === 'HAS_NOT_ROLE') && f.after === r.id));
        },
        getAvailableNotificationRoles() {
            return this.getRoles().filter(r => !this.notificationData.roles.includes(r.id));
        },
        getRoles() {
            return window.roles.map(r => { return { id: r.id, name: r.title } });
        },
        getRole(id) {
            return this.getRoles().find(r => r.id === id);
        },
        getRelations() {
            return this.models.find(m => m.id === this.eventNotification.model)?.relations || [];
        },
        removeDataFilter(index) {
            this.modelFilter.splice(index, 1);
            this.saveModelFilters();
        },
        loadFilterOptions() {
            axios.get(`${window.api}/event-notification/filter-option`, {
                params: {
                    model: this.eventNotification.model,
                },
            }).then((response) => {
                this.modelFilterOptions = response.data;
            }).catch(e => {
                window.toastr.error(e.response.data.message)
            });
        },
        addFilter() {
            this.modelFilter.push({
                value: this.selectedFilterOptionId,
                before: this.selectedFilterOptionIdBefore,
                after: this.selectedFilterOptionIdAfter,
            });

            this.saveModelFilters();
            this.resetFilterOptions();
        },
        saveModelFilters() {
            axios.put(`${window.api}/event-notification/${this.eventNotification.id}/model`, {
                model_filter: this.modelFilter,
            }).then((response) => {
                window.toastr.success('Filter Added')
            }).catch(e => {
                window.toastr.error(e.response.data.message)
            });
        },
        addSendToRole() {
            this.notificationData.roles.push(this.selectedNotificationRoleId);

            this.saveNotificationSendTo();
            this.selectedNotificationRoleId = null;
        },
        addSendToUser() {
            this.notificationData.users.push(this.selectedNotificationField);

            this.saveNotificationSendTo();
            this.selectedNotificationField = null;
        },
        removeSendToRole(i) {
            this.notificationData.roles.splice(i, 1);
            this.saveNotificationSendTo();
        },
        removeSendToUser(i) {
            this.notificationData.users.splice(i, 1);
            this.saveNotificationSendTo();
        },
        saveNotificationSendTo() {
            axios.put(`${window.api}/event-notification/${this.eventNotification.id}/notification`, {
                to: this.notificationData,
            }).then((response) => {
                window.toastr.success('Addressee Added')
            }).catch(e => {
                window.toastr.error(e.response.data.message)
            });
        },
        renderAfter(filterRow) {
            if (filterRow.before === 'HAS_ROLE' || filterRow.before === 'HAS_NOT_ROLE') {
                return this.getRole(filterRow.after)?.name;
            }

            return filterRow.after;
        }
    }
}
</script>
