<template>

    <card-component :title="getLabel()">
        <input v-if="value.id" type="hidden" :name="this.name + '[id]'" :value="value.id">
        <div class="row">
            <div class="col-md-6">
                <floating-label-component :name="this.name + '[name]'" label="Nome Completo do Cliente"
                    v-model="value.name" />
            </div>
            <div class="col-md-3">
                <floating-label-component :name="this.name + '[nationality]'" label="Nacionalidade"
                    v-model="value.nationality" />
            </div>
            <div class="col-md-3">
                <floating-label-component :name="this.name + '[birth_local]'" label="Local de Nascimento"
                    v-model="value.birth_local" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <floating-label-component :name="this.name + '[address]'" label="Morada do Cliente"
                    v-model="value.address" />
            </div>
            <div class="col-md-3">
                <floating-label-component :name="this.name + '[zipcode]'" label="Código Postal"
                    v-model="value.zipcode" />
            </div>
            <div class="col-md-3">
                <floating-label-component :name="this.name + '[city]'" label="Localidade" v-model="value.city" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <floating-label-component :name="this.name + '[nif]'" label="NIF" v-model="value.nif" />
            </div>
            <div class="col-md-4">
                <floating-label-component :name="this.name + '[profession]'" label="Profissão"
                    v-model="value.profession" />
            </div>
            <div class="col-md-4">
                <floating-label-component :name="this.name + '[employer]'" label="Entidade Patronal"
                    v-model="value.employer" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <drop-down-component :name="this.name + '[document_type]'" label="Documento de Identificação"
                    v-model="value.document_type" :items="clientDocumentTypes" />
            </div>
            <div class="col-md-2">
                <floating-label-component :name="this.name + '[document_number]'" label="Nº do Documento"
                    v-model="value.document_number" />
            </div>
            <div class="col-md-2">
                <floating-label-date-component :name="this.name + '[document_expiry_date]'" label="Data de Validade"
                    v-model="value.document_expiry_date" />
            </div>
            <div class="col-md-2">
                <floating-label-component :name="this.name + '[document_issuer]'" label="Entidade Emissora"
                    v-model="value.document_issuer" />
            </div>
            <div class="col-md-3">
                <file-upload-component innerLabel="Documento de Identificação" :name="this.name + '[file_id]'" icon="citizen_card" v-model="value.file_id" @onChange="fileChanged($event)"></file-upload-component>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <drop-down-component :name="this.name + '[civil_status]'" label="Estado Civil"
                    v-model="value.civil_status" :items="civilStatusTypes" />
            </div>
            <div class="col-md-3" v-if="this.isMarriageRegimeVisible()">
                <drop-down-component :name="this.name + '[marriage_regime]'" label="Regime de Casamento"
                    v-model="value.marriage_regime" :items="marriageRegimeTypes" />
            </div>
            <div class="col-md-3">
                <floating-label-date-component :name="this.name + '[birth_date]'" label="Data de Nascimento"
                    v-model="value.birthdate" />
            </div>
            <div class="col-md-3">
                <drop-down-component :name="this.name + '[language]'" placeholder="Língua" v-model="value.language" 
                    :items="clientLanguages" />
            </div>
        </div>
    </card-component>
</template>

<script>
export default {
    mounted() {
    },
    props: {
        value: Object,
        name: String,
        civilStatusTypes: Array,
        marriageRegimeTypes: Array,
        clientDocumentTypes: Array,
        clientLanguages: Array,
    },
    methods: {
        fileChanged(e) {
            this.value.file_id = e;
        },
        getLabel() {
            if (this.value.business_type === 'BUY_AND_SELL') {
                return 'Identificação do Comprador'
            }

            if (this.value.business_type === 'RENT') {
                return 'Identificação do Arrendatário'
            }

            return 'Identificação do Cliente'
        },
        isMarriageRegimeVisible() {
            return this.value.civil_status === 'MARRIED';
        }
    }
}
</script>

