<template>
    <div class="card mb-3">
        <div class="card-header">
            Cargo: {{ role.title }}
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Feature</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="feature in this.features">
                        <td>{{ feature.name }}</td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" :checked="isFeatureChecked(feature.value)"
                                    @change="updateFeature($event, feature.value)">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Permissões</th>
                        <th scope="col">Ver</th>
                        <th scope="col">Editar</th>
                        <th scope="col">Eliminar</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="permission in permissionsMapping">
                        <td>{{ permission.name }}</td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox"
                                    :checked="isPermissionChecked(permission.key, 'index')"
                                    @change="updatePermission($event, permission.key, 'index')">
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox"
                                    :checked="isPermissionChecked(permission.key, 'edit')"
                                    @change="updatePermission($event, permission.key, 'edit')">
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox"
                                    :checked="isPermissionChecked(permission.key, 'delete')"
                                    @change="updatePermission($event, permission.key, 'delete')">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
    },
    props: {
        role: Object,
        permissions: Array,
        features: Array,
        rolePermissions: Array,
        routeAdd: String,
        routeRemove: String,
    },
    data() {
        return {
            hrefAdd: this.routeAdd,
            hrefRevoke: this.routeRemove,
            availableRolePermissions: this.rolePermissions ?? [],
            availablePermissions: this.permissions,
            permissionsMapping: [{
                name: 'Marketing',
                key: 'marketing'
            },
            {
                name: 'Angariações',
                key: 'fundraisers'
            },
            {
                name: 'Contratos',
                key: 'contracts'
            },
            // {
            //     name: 'Escrituras',
            //     key: 'scriptures'
            // },
            {
                name: 'Financeiro',
                key: 'financial'
            },
            {
                name: 'Faturação',
                key: 'invoice'
            },
            // {
            //     name: 'Honorários',
            //     key: 'fees'
            // },
            {
                name: 'Crédito',
                key: 'credit'
            },
            {
                name: 'Apoio Jurídico',
                key: 'juridical_support'
            },
            // {
            //     name: 'Recrutamento',
            //     key: 'recruitment'
            // },
            // {
            //     name: 'Leads',
            //     key: 'leads'
            // },
            ],
        }
    },
    methods: {
        togglePermission: function () {
            this.$refs.form.submit();
        },
        isPermission: function (key) {
            return !!this.availablePermissions.find(p => p.name === key);
        },
        getPermissionId: function (key) {
            if (!this.isPermission(key)) {
                return key;
            }

            return this.availablePermissions.find(p => p.name === key).id;
        },
        getPermissionKey: function (key, type) {
            return key + '.' + type;
        },
        isPermissionChecked: function (permissionKey, type) {
            const key = this.getPermissionKey(permissionKey, type);
            if (!this.isPermission(key)) {
                return false;
            }

            return !!this.availableRolePermissions.find(name => name === key);
        },
        updatePermission: function (event, permissionKey, type) {
            const isChecked = event.target.checked;
            const permissionId = this.getPermissionId(this.getPermissionKey(permissionKey, type))
            if (isChecked) {
                axios.post(this.hrefAdd, {
                    permission: permissionId,
                })
                    .then(response => {
                        if (response.status !== 200) {
                            event.target.checked = false;
                        }
                    })
            } else {
                axios.post(this.hrefRevoke + '/' + permissionId, {
                    _method: 'delete',
                    permission: permissionId,
                })
                    .then(response => {
                        if (response.status !== 200) {
                            event.target.checked = true;
                        }
                    })
            }
        },
        isFeatureChecked: function (feature) {
            return !!this.availableRolePermissions.find(name => name === feature);
        },
        updateFeature: function (event, feature) {
            const isChecked = event.target.checked;
            const permissionId = this.getPermissionId(feature)
            if (isChecked) {
                axios.post(this.hrefAdd, {
                    permission: permissionId,
                })
                    .then(response => {
                        if (response.status !== 200) {
                            event.target.checked = false;
                        }
                    })
            } else {
                axios.post(this.hrefRevoke + '/' + permissionId, {
                    _method: 'delete',
                    permission: permissionId,
                })
                    .then(response => {
                        if (response.status !== 200) {
                            event.target.checked = true;
                        }
                    })
            }
        }
    }
}
</script>

<style>
.loading {
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
}
</style>

