<template>
    <div>
        <card-component title="Pedido de Fatura">
            <div class="row">
                <div class="col-md-6">
                    <drop-down-component name="agency_id" label="Agência" v-model="invoice.agency_id" :items="agencies" />
                </div>
                <div class="col-md-6">
                    <drop-down-component name="agent_id" label="Agente" :value="invoice.agent_id" :items="userList" />
                </div>
                <div class="col-12">
                    <option-component name="client_type" label="Tipo de Cliente" v-model="invoice.client_type"
                        :options="clientTypes" />
                </div>
            </div>
        </card-component>

        <card-component title="Cliente" v-if="invoice.client_type">
            <invoice-clients-component v-if="invoice.client_type === 'SELLER'" :invoice-clients="invoice.clients" />
            <invoice-buyer-internal-component v-if="invoice.client_type === 'BUYER_INTERNAL'" :invoice="invoice" />
            <invoice-buyer-external-component v-if="invoice.client_type === 'BUYER_EXTERNAL'" :invoice="invoice" />
        </card-component>

        <card-component title="Dados de Imóvel">
            <div class="row">
                <div class="col-md-6">
                    <floating-label-component name="property_address" label="Morada do Imóvel"
                        v-model="invoice.property_address" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="property_zipcode" label="Código Postal"
                        v-model="invoice.property_zipcode" />
                </div>
                <div class="col-md-3">
                    <floating-label-component name="property_city" label="Localidade" v-model="invoice.property_city" />
                </div>
            </div>
        </card-component>

        <card-component title="Negócio">
            <div class="row">
                <div class="col-12 mb-3">
                    <option-component name="business_type" label="Tipo de Negócio" v-model="invoice.business_type"
                        :options="businessTypes" />
                </div>
                <div class="col-md-4">
                    <floating-label-component name="business_process" label="Número de Processo"
                        v-model="invoice.business_process" />
                </div>
                <div class="col-md-4">
                    <floating-label-currency-component label="Valor" name="business_value"
                        v-model="invoice.business_value" />
                </div>
                <div class="col-md-4">
                    <floating-label-percentage-component name="business_comission" label="Comissão"
                        v-model="invoice.business_comission" />
                </div>
            </div>
        </card-component>

        <card-component title="Partilha">
            <invoice-share-component :invoice="invoice" :share-types="shareTypes" :invoice-percentages="invoicePercentages" />voice
        </card-component>
    </div>
</template>

<script>
export default {
    mounted() {

    },
    props: {
        originalInvoice: {
            type: Object,
            required: true,
        },
        clientTypes: {
            type: Array,
            required: true,
        },
        shareTypes: {
            type: Array,
            required: true,
        },
        businessTypes: {
            type: Array,
            required: true,
        },
        invoicePercentages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            invoice: this.prepareInvoice(),
            agencies: [...window.agencies],
        }
    },
    computed: {
        userList() {
            const field = 'agent_id';
            if (!this.invoice.agency_id) {
                return [];
            }

            const agency = this.getAgency(this.invoice.agency_id);
            const roleIds = window.modelConfiguration?.Invoice && window.modelConfiguration.Invoice[field] || []
            const roles = Object.values(agency.roles).filter(r => roleIds.find(id => id === r.id));
            const users = roles.map(r => r.users).flat()

            let result = [];
            const map = new Map();
            for (const item of users) {
                if (!map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name
                    });
                }
            }

            result.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            result = [
                {
                    id: null,
                    name: ''
                },
                ...result,
            ]
            return result;
        }
    },
    methods: {
        prepareInvoice() {
            const i = { ...this.originalInvoice };

            if (!i.clients) {
                i.clients = [];
            }
            if (!i.invoices) {
                i.invoices = [];
            }
            return i;
        },
        getAgency(id) {
            return this.agencies.find(a => a.id === id);
        },
    }
}
</script>
